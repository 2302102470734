.product-group-card {
	position: relative;
	width: 150px;
	height: 150px;
	display: flex;
	justify-content: center;
	text-align: center;
	padding: 1rem 1rem;
	background-color: #fff;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
	transition: 0.2s ease-in-out;
	margin: 10px;
	border-radius: 7px;
	display: flex;
	justify-content: center;
	text-align: center;
  }
  
  .product-group-card:hover {
	transform: translateY(-5px);
  }
  
  .product-group-card::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
	  to bottom,
	  rgba(255, 32, 32, 0.54),
	  rgb(0 0 0 / 77%)
	);
	z-index: 2;
	transition: 0.5s all;
	opacity: 1;
	border-radius: 7px;
	
  }
  
  .product-group-card:hover::before {
	/* opacity: 0; */
	border-radius: 7px;
  }
  
  .product-group-card img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border: 5px;
	border-radius: 7px;
  }
  
  .product-group-card .overlay {
	position: relative;
	z-index: 3;
	color: #fff;
	opacity: 1;
	transform: translateY(30px);
	transition: 0.5s all;
	top: 22px;
  }
  
  .product-group-card:hover .overlay {
	/* opacity: 0; */
	transform: translateY(30px);
	font-weight: bold;
  }
  
  .overlay {
	display: flex;
	justify-content: center;
	text-align: center;
  }
  
  .text-h1 {
	
  }
  
  .text-p {
	letter-spacing: 1px;
	
  }
  
  .link-a {
	color: #fff;
	cursor: pointer;
	transition: 0.4s ease-in-out;
	font-weight: 700;
	
	
  }
  
