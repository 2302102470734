.myAcountCol {
  margin: 15px 0px;
}
.iconLink {
  color: #000;
}
.formCard {
  margin: 15px 0px;
  padding: 15px;
}
.radioDiv {
  display: flex;
  gap: 10px;
  padding-bottom: 15px;
}
.emailDiv {
  padding-top: 38px;
}
.changPassowrdDiv {
  padding: 30px 0px;
}
.mainRowMyAcoount {
  padding: 60px 0px;
}
@media print {
  .no-print {
    display: none;
  }
}
