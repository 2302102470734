.alert-info {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px 20px;
  border-radius: 3px;
  background: #fff;
  border-top: 3px solid #000;
  margin-top: 30px;
}
.account-card {
  margin-bottom: 30px !important;
  border-radius: 8px;
  padding: 0px 30px 30px;
  background: #fff;
}
.account-title {
  padding: 18px 0px;
  margin-bottom: 25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  border-bottom: 1px solid #888;
}
.account-title::before {
  position: absolute;
  content: "";
  bottom: -2px;
  left: 0px;
  width: 50px;
  height: 2px;
  background: #000;
}
thead tr {
  background: #000;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
  width: 100%;
}
.inner-section {
  /* padding: 80px 0px; */
}
.tbaleBorder {
  border-right: 1px solid;
}
.tableRow {
  border: 1px solid gray;
}
thead tr th {
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  text-transform: capitalize;
  padding: 12px 20px;
  color: #ffff;
  border-right: 1px solid #fff;
}
thead tr th:first-child {
  border-radius: 6px 0px 0px 6px;
}
tbody tr td {
  padding: 12px 20px;
  text-align: center;
  border: 1px solid gray;
  background-color: #fff;
}
thead tr th:last-child {
  border-right: none;
  border-radius: 0px 6px 6px 0px;
}
.loginLink {
  color: #000;
}
.alert-info p a {
  margin-left: 5px;
  text-decoration: underline;
}
.mainCardAddres {
  padding: 20px;
}
.selctLable {
  width: 100%;
  height: 45px;
  border-radius: 6px;
  padding: 0px 20px 2px;
  color: gray;
  background: #fff;
  border: 1px solid gray;
  margin: 10px 0px;
}
.addresLable {
  padding-left: 10px;
}
.profile-card {
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 25px;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  position: relative;
  cursor: pointer;
}
.form-label {
  font-weight: 500;
  margin: 0px 0px 7px 3px;
  white-space: nowrap;
  text-transform: capitalize;
}
.contactInputLable {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 7px;
  width: 100%;
}
.checkout-check {
  display: flex;
  gap: 10px;
  margin-bottom: 25px;
}
.checkOutBtn {
  width: 180px;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 0px;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  background: #000;
}
