.haderTitle {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding: 15px;
}
.print-order {
  font-size: 15px;
  padding: 10px 10px 5px !important;
  border: 2px dotted #ccc;
  font-weight: 700;
  text-align: right !important;
}
.billRow {
  background: #000;
  align-items: center;
}
.order-ttl {
  font-weight: 700;
  margin-left: 14px;
  margin-right: 14px;
  border-bottom: 2px dotted #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
}
table.meta th {
  width: 40%;
  border-color: gray;
  background-color: #efefef;
  font-weight: 600;
  color: #000;
}
table.meta-2 th,
table.meta-2 td {
  border-radius: 0.25em;
  border-style: solid;
  border-width: 1px;
  padding: 0.5em;
  position: relative;
  text-align: left;
  color: #000;
}
.print-order:hover {
  border: 2px dotted #000;
  cursor: pointer;
}
table.meta-2 th {
  width: 30%;
  border-color: gray;
  color: #000;
  font-weight: 600;
  table.meta-1 th {
    /* width: 40%; */
    background: #efefef;
  }
}
.mainSection {
  padding: 60px 0px;
}
table.meta-1 th,
table.meta-1 td {
  border-radius: 0.25em;
  border-style: solid;
  border-width: 1px;
  padding: 0.5em;
  position: relative;
  text-align: left;
  vertical-align: top;
}
table.meta-1 th {
  /* width: 40%; */
  background: #efefef;
  border-color: gray;
  font-weight: 600;
}
table.meta th,
table.meta td {
  border-radius: 0.25em;
  border-style: solid;
  border-width: 1px;
  padding: 0.5em;
  position: relative;
  text-align: left;
}
