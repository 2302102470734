@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.home,
.reports,
.products,
.team,
.reports,
.messages,
.messages-1,
.messages-2,
.support,
.users,
.revenue {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.bottom-part {
  background-color: #ebebeb;
  /* min-height: 70px; */
  border-bottom: 1px solid #fff;
  padding: 20px 0px;
}
.form-control {
  border: 1px solid gray;
  border-radius: 5px;

  height: 40px;
  padding-left: 20px;
  transition: 0.5s;
  width: 100%;
  /* margin-left: -32px; */
  background: #fffdfd;
}
.form-control-addcart {
  border: 1px solid gray;
  border-radius: 5px;
  margin: 15px 0px;
  height: 40px;
  padding-left: 20px;
  transition: 0.5s;
  width: 100%;
  background: #fffdfd;
}
.mainDivColorOption {
  display: flex;
  gap: 15px;
}
.form-controlTwo {
  border: 1px solid gray;
  border-radius: 5px;
  height: 40px;
  padding-left: 20px;
  transition: 0.5s;
  width: 60%;
  margin-left: 0px;
  background: #fffdfd;
  margin-right: 32px;
}
.aplicationTitle {
  padding: 15px 0px;
}
.aplicationTitleOne {
  padding: 30px 0px;
}
.dealerContainer {
  padding: 60px 0px;
}
.search-icon {
  position: absolute;
  left: 295px;
}
.me-5 {
  margin-right: 1rem !important;
}
.me-5 svg {
  border: 1px solid black;
  font-size: 12px;
  border-radius: 5px;
}
.me-5 span:hover {
  color: #f05454 !important;
}

.btn-light {
  border: 1px solid gray;
}
.dropdown-menu {
  --bs-dropdown-min-width: 5.3rem;
}
.cart-num {
  background: rgb(238, 255, 0);
  border-radius: 50%;
  padding: 0px 3px;
  position: relative;
  bottom: 10px;
  right: 10px;
  font-size: 11px;
  color: #000000 !important;
}
