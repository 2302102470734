@media only screen and (max-width: 768px) {
  
  
  body {
    font-size: 15px;
  }


  
  .mtb-100 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .js-image-zoom__zoomed-image{
    display:none !important
  }
  .js-image-zoom__zoomed-area{
    display: none !important;
  }
 .idRPHM{
  left: -57px !important;
 }

  .mt-100 {
    margin-top: 50px;
  }
  .mb-100 {
    margin-bottom: 50px;
  }

  /* .figureContent {
    height: auto;
  } */
  .pt-100 {
    padding-top: 50px;
  }
  .pb-70 {
    padding-bottom: 20px;
  }
  .pb-100 {
    padding-bottom: 50px;
  }
  .container {
    max-width: 540px !important;
  }
  .container-fluid {
    max-width: 540px !important;
  }
  .section-title {
    text-align: center;
  }
  .owl-thumbs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }
  .item.owl-thumb-item {
    width: 33%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 0 5px;
  }
  .header-area {
    text-align: center;
  }
  .header-area .left {
    margin-bottom: 15px;
    margin-top: -5px;
  }
  .header-area .left ul li {
    margin-right: 0;
    margin-bottom: 5px;
  }
  .header-area .left ul li:last-child {
    margin-bottom: 0;
  }
  .header-area .right {
    text-align: center;
  }
  .header-area .right .inner {
    margin-right: 3px;
    margin-left: 3px;
    margin-bottom: 5px;
  }
  .header-area .right .inner:last-child {
    margin-bottom: 0;
  }
  .nav-top-area {
    text-align: center;
  }
  .nav-top-area .left {
    display: none;
  }
  .nav-top-area .middle .form-group .inner {
    position: relative;
    top: 0;
    left: 0;
  }
  .nav-top-area .middle .form-group .inner .nice-select {
    width: 100%;
    margin-bottom: 5px;
  }
  .nav-top-area .middle .form-group .inner .nice-select .list {
    width: 100%;
    display: block;
  }
  .nav-top-area .middle .form-group .form-control {
    padding-left: 10px;
    height: 50px;
    margin-bottom: 5px;
  }
  .nav-top-area .middle .form-group .btn {
    top: 55px;
  }
  .descriptionDiv {
    justify-content: center;
  }
  .nav-top-area .right {
    text-align: center;
  }
  .nav-top-area .right ul li {
    margin-right: 5px;
  }
  .side-nav {
    text-align: center;
    padding-bottom: 15px;
    -webkit-box-shadow: 0 15px 20px 0 #dddddd63;
    box-shadow: 0 15px 20px 0 #dddddd63;
  }
  .side-nav h4 {
    font-size: 15px;
  }
  .banner-area .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .banner-area .banner-h1 {
    font-size: 22px !important;
  }
  .banner-area .banner-p {
    font-size: 16px !important;
  }
  .banner-area .tp-leftarrow {
    -webkit-transform: matrix(1, 0, 0, 1, -50, -110) !important;
    transform: matrix(1, 0, 0, 1, -50, -110) !important;
  }
  .banner-area .tp-rightarrow {
    -webkit-transform: matrix(1, 0, 0, 1, 10, -110) !important;
    transform: matrix(1, 0, 0, 1, 10, -110) !important;
  }
  .products-area .sorting-menu {
    padding-bottom: 25px;
  }
  .products-area .sorting-menu ul {
    text-align: center;
  }
  .products-area .sorting-menu ul li {
    margin-right: 5px;
    margin-left: 5px;
  }
  .products-thumb {
    padding: 15px 5px;
  }
  .products-item {
    text-align: center;
    height: 90%;
  }
  .products-item .top .inner {
    text-align: center;
  }
  .products-item .top .inner h3 {
    font-size: 18px;
  }
  .buy-area {
    text-align: center;
  }
  .buy-area .buy-shape img:nth-child(1) {
    max-width: 100px;
  }
  .buy-area .buy-content {
    max-width: 500px;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .buy-area .buy-content h2 {
    font-size: 25px;
    margin-top: -5px;
  }
  .testimonials-area .owl-prev {
    left: 0;
    top: 83%;
  }
  .testimonials-area .owl-next {
    right: 0;
    top: 83%;
  }

  .support-item {
    padding: 30px 15px;
    margin-left: 0;
    text-align: center;
  }
  .support-item i {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 20px;
  }
  .blog-item {
    text-align: center;
  }
  .footer-area .footer-shape img {
    max-width: 68px;
  }
  .footer-area .footer-shape img:nth-child(2) {
    left: 0;
    bottom: 50px;
  }
  .payment-cards {
    text-align: center;
  }
  .payment-cards ul li {
    margin-right: 2px;
    margin-left: 2px;
  }
  .payment-cards ul li a {
    max-width: 55px;
  }
  .social-links {
    text-align: center;
  }
  .popup-modal .modal-header h2 {
    font-size: 20px;
  }
  .popup-modal .modal-header h2 span {
    top: 5px;
  }
  .popup-modal .modal-content {
    padding: 30px 5px;
  }
  .popup-modal .modal-body .cart-table .table {
    margin-bottom: 30px;
  }
  .popup-modal .modal-body .cart-table .table tr th {
    padding-left: 3px;
    padding-right: 3px;
  }
  .popup-modal .modal-body .cart-table .table tr td {
    padding-left: 3px;
    padding-right: 3px;
  }
  .popup-modal .modal-body .cart-table img {
    max-width: 40px;
  }
  .popup-modal .modal-body .cart-table h3 {
    font-size: 13px;
  }
  .popup-modal .modal-body .cart-table .common-btn {
    padding: 8px;
    font-size: 12px;
  }
  .popup-modal.modal-right.show .modal-dialog {
    -webkit-transform: translate(0, 70px);
    transform: translate(0, 70px);
  }
  .sale-item {
    padding: 30px 10px 30px 15px;
    margin-right: 0;
  }
  .sale-item .inner h3 {
    font-size: 25px;
  }
  .sale-item img {
    right: 5px;
    max-width: 160px;
  }
  .products-area.two .owl-theme .owl-nav {
    width: 100%;
    margin-top: -4px;
    margin-bottom: 28px;
  }
  .products-area.two .owl-prev {
    position: relative;
    left: 0;
    top: 0%;
  }
  .products-area.two .owl-next {
    position: relative;
    right: 0;
    top: 0%;
  }
  .buy-area.two .buy-shape img:nth-child(2) {
    display: none;
  }
  .buy-area.two .buy-content {
    margin-bottom: 30px;
  }
  .deal-area .deal-black h3 {
    font-size: 17px;
  }
  .deal-item {
    padding: 30px 15px;
    text-align: center;
  }
  .deal-item .inner .left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .deal-item .inner .right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .deal-item .inner .right h3 {
    font-size: 20px;
  }
  .deal-item .inner .right .cart-wishlist {
    top: 0;
  }
  .video-item {
    text-align: center;
  }
  .video-item .inner h3 {
    font-size: 20px;
  }
  .video-item .inner-two h2 {
    font-size: 25px;
  }
  .video-area .owl-theme .owl-nav {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 30px;
  }
  .buy-area.three .buy-img {
    margin-bottom: 30px;
  }
  .buy-area.three .buy-content .buy-btn {
    margin-right: 5px;
    margin-left: 5px;
  }
  .buy-area.three .buy-content .common-btn {
    margin-right: 5px;
    margin-left: 5px;
  }
  .testimonials-area.three .testimonials-item {
    padding: 30px 15px 75px;
  }
  .testimonials-area.three .testimonials-item p {
    font-size: 20px;
  }
  .blog-area.three .blog-item-right .inner {
    text-align: center;
  }
  .blog-area.three .blog-item-right .inner ul li:nth-child(1) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
    max-width: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  .blog-area.three .blog-item-right .inner ul li:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
  }
  .banner-area-two {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
  .banner-area-two .banner-item {
    height: 100%;
    padding-top: 55px;
    padding-bottom: 65px;
  }
  .banner-area-two .banner-item .banner-content {
    max-width: 800px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  .banner-area-two .banner-item .banner-content h1 {
    font-size: 23px;
  }
  .banner-area-two .banner-item .banner-content p {
    font-size: 16px;
  }
  .banner-area-two .banner-item .banner-img img:nth-child(1) {
    height: auto;
    max-width: 500px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .banner-area-two .banner-item .banner-img img:nth-child(2) {
    bottom: 20px;
    max-width: 35px;
  }
  .banner-area-two .owl-theme .owl-nav {
    position: relative;
    bottom: 0;
  }
  .page-title-area {
    height: 425px;
  }
  .page-title-area .title-content {
    text-align: center;
    margin-top: 75px;
  }
  .page-title-area .title-content h2 {
    font-size: 35px;
    margin-bottom: 10px;
  }
  .page-title-area .title-img img:nth-child(1) {
    display: none;
  }
  .page-title-area .title-img img:nth-child(2) {
    left: 20%;
    bottom: 10px;
    -webkit-animation: page-title-ani-one 10s infinite linear;
    animation: page-title-ani-one 10s infinite linear;
  }
  .page-title-area .title-img img:nth-child(3) {
    left: 15%;
    top: 125px;
  }
  .page-title-area .title-img img:nth-child(4) {
    max-width: 90px;
  }
  .about-area .about-content {
    text-align: start;
  }
  .about-area .about-content .top h2 {
    font-size: 30px;
  }
  .about-area .about-content .middle ul li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 25px 15px;
    margin-bottom: 25px;
    margin-right: 0;
  }
  .priceTitle{
    font-size: 13px;
  }
  .about-area .about-content .middle ul li img {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 15px;
  }
  .about-area .about-img {
    text-align: center;
  }
  .about-area .about-img img:nth-child(1) {
    padding-left: 0;
    margin-bottom: 30px;
  }
  .about-area .about-img img:nth-child(2) {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-bottom: 30px;
  }
  .about-area .about-img img:nth-child(3) {
    margin-top: 0;
    margin-left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .blog-details-area .details-item {
    margin-bottom: 30px;
  }
  .blog-details-area .details-item .details-img {
    text-align: center;
  }
  .blog-details-area .details-item .details-img h2 {
    font-size: 25px;
  }
  .blog-details-area .details-item .details-img .details-date li {
    margin-right: 10px;
  }
  .blog-details-area .details-item .details-arrow-page {
    text-align: center;
  }
  .blog-details-area
    .details-item
    .details-arrow-page
    .left
    ul
    li:nth-child(1) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 55px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
  .blog-details-area
    .details-item
    .details-arrow-page
    .left
    ul
    li:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
  }
  .blog-details-area .details-item .details-arrow-page .left ul li h3 {
    font-size: 20px;
  }
  .blog-details-area .details-item .details-arrow-page .right {
    text-align: center;
  }
  .blog-details-area
    .details-item
    .details-arrow-page
    .right
    ul
    li:nth-child(1) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .blog-details-area
    .details-item
    .details-arrow-page
    .right
    ul
    li:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 55px;
    margin-left: auto;
    margin-right: auto;
  }
  .blog-details-area .details-item .details-arrow-page .right ul li h3 {
    font-size: 20px;
  }
  .blog-details-area .details-item .details-comments ul li a {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 10px;
  }
  .blog-area .widget-area {
    margin-top: 30px;
  }
  .blog-area.four .widget-area {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .product-details-area {
    text-align: center;
  }
  .product-details-area .top .top-content h2 {
    font-size: 24px;
  }
  .product-details-area .bottom .bottom-comment .comments {
    text-align: left;
  }
  .product-details-area .bottom .bottom-comment .comments .reviews {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 10px;
  }
  .order-tracking-area .order-content h2 {
    text-align: center;
  }
  .common-faq-area .faq-item .accordion li .faq-head {
    padding: 18px 50px 18px 18px;
    line-height: 1.3;
  }
  .common-faq-area .faq-item .accordion li .faq-head:after {
    right: 0;
  }
  .contact-area .contact-info {
    text-align: center;
  }
  .contact-area .contact-info .info li {
    padding-left: 0;
  }
  .contact-area .contact-info .info li i {
    position: relative;
    margin-bottom: 10px;
  }
  .contact-area .contact-info .social-links {
    text-align: center;
  }
  .user-area .user-item {
    padding: 30px;
  }
  .error-area .error-content h1 {
    font-size: 70px;
  }
  .return-policy-area {
    text-align: center;
  }
  .return-policy-area .return-policy-item h2 {
    font-size: 25px;
  }
  .rules-area {
    text-align: start;
  }
  .rules-area .rules-item .inner ul {
    text-align: left;
  }
  #modal-subscribe .modal-dialog .modal-content {
    padding: 30px 15px 0;
  }
  #modal-subscribe .modal-dialog .modal-content h2 {
    text-align: center;
    font-size: 20px;
  }
  #modal-subscribe .modal-dialog .modal-content p {
    text-align: center;
  }
  #modal-subscribe .subscribe-img {
    display: none;
  }
  .number {
    padding: 2px 0 5px;
  }
  .number li .minus,
  .number li .plus {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 16px;
  }
  .number li input {
    height: 15px;
    width: 15px;
    line-height: 15px;
    font-size: 12px;
    padding-left: 0;
  }
  .popup-modal.modal-right .modal-dialog {
    padding: 0 5px;
  }
  .sale-area {
    /* margin-top: 105px; */
  }
  .sale-area .row {
    padding-top: 30px;
  }
  .header-area .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav-top-area .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .product-details-area #thumbs.owl-carousel.owl-drag .owl-item {
    max-width: 90px;
  }
  .manDivPriceRangeTitle {
    height: 100px;
  }
  .css-1nbic85 {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 426px) {
  .tabletFlex {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 575px) {
  .product-details-area #thumbs.owl-carousel.owl-drag .owl-item {
    max-width: 100px;
  }
  .buy-area .buy-shape img:nth-child(1) {
    display: none;
  }
  .products-thumb {
    height: 120px;
  }
  .iiNqQG{
    margin-left: -35% !important;
  }
  .products-item.top.inner {
    padding-left: 0px;
  }
  .products-area.two .products-thumb i {
    font-weight: 400;
    font-size: 30px;
  }
  .common-btn {
    padding: 7px 18px;
  }
  .products-thumb span {
    font-size: 18px;
  }
  .sizeSection {
    display: flex;
    gap: 10px;
  }
  .deal-area .deal-black {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .customiseBtn {
    padding: 11px 9px;
    font-size: 9px;
  }
  .mainDivContinueCart {
    width: 50%;
  }
  .customiseBtn {
    padding: 10px 4px;
    font-size: 8px;
  }
  .toggle-div {
    /* margin-left: 21rem !important; */
  }
  .nav-main-div {
    width: 375px !important;
  }
  .nav-logo {
    padding: 10px !important;
    width: 110px !important;
  }
  .search-icon {
    left: 290px !important;
  }
  .acrodingContent {
    display: flex !important;
  }
  .acrodingCol {
    flex-basis: 49% !important;
  }

  .form-controlTwo {
    /* position: relative; */
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 25px !important;
    width: 100% !important;
  }
  .footer-area .footer-shape img {
    max-width: 35px !important;
  }
  .css-1nbic85 {
    width: 100% !important;
  }
  .categoryImg {
    margin-bottom: 30px;
  }
}



/* @media only screen and (min-width:374px) and (max-width:400){
  .XNbel{
  margin-left : -210px !important;
  }
} */
@media only screen and (min-width: 374px) and (max-width: 400px) {
  .product-details-area #thumbs.owl-carousel.owl-drag .owl-item {
    max-width: 115px;
  }
  .XNbel {
    margin-left: -225px !important;
   }
   .idRPHM {
    font-size: 1.1rem;
    color: #fff!important;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    left: -221px !important
   }
   .erocaA {
    font-size: 1.1rem;
    color: #fff!important;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    left: -200px;
   }
  /* .figureContent {
    height: auto;
  } */
}
@media only screen and (min-width: 470px) and (max-width: 575px) {
  .product-details-area #thumbs.owl-carousel.owl-drag .owl-item {
    max-width: 135px;
  }
}

@media screen and (max-width: 3840px) and (min-width: 2560px) {
  .iHuAPa {
    margin-left: 155rem !important;
  }
  .search-icon {
    left: 55% !important;
  }
  .fxSFUJ {
    margin-left: 155rem !important;
  }
}
@media only screen and (min-width: 424px) and (max-width: 575px) {
  
  .erocaA {
    font-size: 1.1rem;
    color: #fff!important;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    left: -200px;
  }
  .products-area .sorting-menu ul li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 47%;
    flex: 0 0 47%;
    max-width: 47%;
  }
  .deal-area .deal-black {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .customiseBtn {
    padding: 11px 9px;
    font-size: 9px;
  }
  .search-icon {
    position: absolute !important;
    left: 345px !important;
    z-index: 999;
    font-size: 13px;
    color: #757575 !important;
  }
  .form-controlTwo {
    position: relative;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 25px !important;
    width: 100% !important;
  }
  .toggle-div {
    /* margin-left: 24rem !important; */
  }
  .nav-logo {
    padding: 10px !important;
    width: 110px !important;
  }
  .help-btn-div {
    margin-bottom: 15px;
  }
  .copyright-area .copyright-item p {
    font-size: 10px;
  }
  .buy-area .buy-shape img:nth-child(1) {
    display: none;
  }
}
@media only screen and (min-width: 497px) and (max-width: 767px) {
  .sale-area {
    margin-top: 90px;
  }
  .side-nav h4 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sale-item {
    margin-right: 0;
  }
  .sale-item .inner h3 {
    font-size: 20px;
  }
  .support-item h3 {
    font-size: 18px;
  }
  .banner-area-two {
    margin-top: 120px;
  }
  .product-details-area #thumbs.owl-carousel.owl-drag .owl-item {
    max-width: 110px;
  }
  .products-item .top .inner h3 {
    font-size: 15px;
  }
  #modal-subscribe .subscribe-img {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 766px) {
  .products-area .sorting-menu ul li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
    max-width: 48%;
  }
  .eRWlqt{
    margin-left: 90% !important;
  }
  .kXyppV {
    margin-left: 90% !important;
  }
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  body {
    font-size: 15px;
  }
  .iiNqQG{
    margin-left: -195px !important;
  }
  .changingsize {
    text-align: center;
    font-size: 18px !important;
  }

 
  .mtb-100 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .mt-100 {
    margin-top: 70px;
  }
  .mb-100 {
    margin-bottom: 70px;
  }
  .ptb-100 {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  .pt-100 {
    padding-top: 70px;
  }
  .pb-70 {
    padding-bottom: 40px;
  }
  .pb-100 {
    padding-bottom: 70px;
  }
  .container {
    max-width: 777px !important;
    margin-top:10px
  }
  .container-fluid {
    max-width: 720px !important;
  }
  .section-title {
    text-align: center;
  }
  .header-area .left ul li {
    margin-right: 5px;
  }
  .header-area .left ul li i {
    margin-right: 2px;
  }
  .header-area .right .inner {
    margin-right: 5px;
  }
  .nav-top-area {
    text-align: center;
  }
  .nav-top-area .left {
    display: none;
  }
  .nav-top-area .middle .form-group {
    margin-bottom: 10px;
  }
  .nav-top-area .right {
    text-align: center;
  }
  .side-nav {
    text-align: center;
    padding-bottom: 0;
    position: absolute;
    top: 18px;
    right: 70px;
  }
  .side-nav h4 {
    font-size: 15px;
  }
  .banner-area .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .banner-area .banner-h1 {
    font-size: 50px !important;
  }
  .banner-area .tp-leftarrow {
    -webkit-transform: matrix(1, 0, 0, 1, -50, -100) !important;
    transform: matrix(1, 0, 0, 1, -50, -100) !important;
  }
  .banner-area .tp-rightarrow {
    -webkit-transform: matrix(1, 0, 0, 1, 10, -100) !important;
    transform: matrix(1, 0, 0, 1, 10, -100) !important;
  }
  .products-area .sorting-menu {
    text-align: center;
    padding-bottom: 25px;
  }
  .products-area .sorting-menu ul li {
    margin-right: 5px;
    margin-left: 5px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 31%;
    flex: 0 0 31%;
    max-width: 31%;
  }
  .products-thumb {
    text-align: center;
    /* margin-top: 20px; */
  }
  .products-item {
    text-align: center;
  }
  .products-item .top .inner {
    text-align: center;
    padding-left: 0;
  }
  .products-item .top .inner h3 {
    font-size: 15px;
  }
  .buy-area .buy-shape img:nth-child(1) {
    max-width: 100px;
    display: none;
  }
  .buy-area .buy-content {
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .buy-area .buy-content h2 {
    font-size: 27px;
    margin-top: -6px;
  }
  .buy-content p{
    font-size: 18px !important;
  }
  .testimonials-area .owl-prev {
    left: 60px;
    top: 87%;
  }
  .testimonials-area .owl-next {
    right: 60px;
    top: 87%;
  }
  .support-item {
    padding: 30px;
    margin-left: 0;
    text-align: center;
  }
  .support-item i {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 20px;
  }
  .blog-item {
    text-align: center;
  }
  .popup-modal.modal-right.show .modal-dialog {
    -webkit-transform: translate(0, 70px);
    transform: translate(0, 70px);
  }
  .sale-area {
    margin-top: 55px;
  }
  .products-area.two .owl-theme .owl-nav {
    margin-top: -4px;
    margin-bottom: 28px;
  }
  .products-area.two .owl-prev {
    position: relative;
    left: 0;
    top: 0%;
  }
  .products-area.two .owl-next {
    position: relative;
    right: 0;
    top: 0%;
  }
  .buy-area.two .buy-shape img:nth-child(2) {
    display: none;
  }
  .buy-area.two .buy-content {
    margin-bottom: 30px;
  }
  .deal-item .inner .left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }
  .video-item .inner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .video-item .inner h3 {
    font-size: 20px;
  }
  .video-item .inner-two {
    padding-left: 5px;
    padding-right: 5px;
  }
  .video-item .inner-two h2 {
    font-size: 25px;
  }
  .video-area {
    text-align: center;
    margin-top: 56px;
  }
  .video-area .owl-theme .owl-nav {
    right: 125px;
    bottom: 30px;
  }
  .video-area .owl-prev {
    width: 35px;
    height: 35px;
    line-height: 39px !important;
    font-size: 20px !important;
  }
  .video-area .owl-next {
    width: 35px;
    height: 35px;
    line-height: 39px !important;
    font-size: 20px !important;
  }
  .buy-area.three .buy-img {
    margin-bottom: 30px;
  }
  .buy-area.three .buy-content .buy-btn {
    margin-right: 5px;
    margin-left: 5px;
  }
  .buy-area.three .buy-content .common-btn {
    margin-right: 5px;
    margin-left: 5px;
  }
  .testimonials-area.three .testimonials-item {
    padding: 50px 15px 75px;
  }
  .banner-area-two {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 85px;
  }
  .banner-area-two .banner-item {
    height: 100%;
    padding-top: 70px;
    padding-bottom: 100px;
  }
  .banner-area-two .banner-item .banner-content {
    max-width: 590px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  .banner-area-two .banner-item .banner-content h1 {
    font-size: 50px;
  }
  .banner-area-two .banner-item .banner-content p {
    font-size: 16px;
  }
  .banner-area-two .banner-item .banner-img img:nth-child(1) {
    height: auto;
    max-width: 600px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .XNbel {
    /* font-size: 1rem;
    background: transparent;
    text-decoration: none;
    display: flex;
    align-items: center; */

    /* margin-left: -282px !important; */
    color: rgb(255, 255, 255) !important;
} 

.idRPHM {
  left: -87px !important;
}

  .banner-area-two .owl-theme .owl-nav {
    position: relative;
    bottom: 0;
  }
  .page-title-area {
    height: 400px;
  }
  .page-title-area .title-content {
    margin-top: 30px;
  }
  .page-title-area .title-content h2 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  .page-title-area .title-img img:nth-child(4) {
    max-width: 85px;
  }
  .about-area .about-content {
    text-align: start;
  }
  .about-area .about-content .middle ul li {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22%;
    flex: 0 0 22%;
    max-width: 22%;
    margin-left: 10px;
    margin-right: 10px;
    padding: 25px 15px;
    margin-bottom: 25px;
  }
  .about-area .about-content .middle ul li img {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 15px;
  }
  .about-area .about-img {
    text-align: center;
  }
  .about-area .about-img img {
    width: 100%;
  }
  .about-area .about-img img:nth-child(1) {
    padding-left: 0;
    margin-bottom: 30px;
  }
  .about-area .about-img img:nth-child(2) {
    margin-top: -19px;
    margin-left: -22px;
    margin-right: 10px;
    max-width: 350px;
  }
  .about-area .about-img img:nth-child(3) {
    margin-top: 22px;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 265px;
  }
  .blog-details-area .details-item {
    margin-bottom: 30px;
  }
  .blog-details-area .details-item .details-img {
    text-align: center;
  }
  .blog-details-area .details-item .details-img h2 {
    font-size: 25px;
  }
  .widget-area .articles .inner ul li:nth-child(1) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
  }
  .blog-area .widget-area {
    margin-top: 30px;
  }
  .blog-area.four .widget-area {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .product-details-area {
    text-align: center;
  }
  .product-details-area .bottom .bottom-comment .comments {
    text-align: left;
  }
  .order-tracking-area .order-content h2 {
    text-align: center;
  }
  .return-policy-area {
    text-align: center;
  }
  .rules-area {
    text-align: start;
  }
  #modal-subscribe .modal-dialog .modal-content {
    padding: 50px 30px 20px;
  }

  .header-area .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .nav-top-area .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .product-details-area #thumbs.owl-carousel.owl-drag .owl-item {
    max-width: 155px;
  }
  .sale-item {
    margin-right: 0;
  }
  .sale-item .inner h3 {
    font-size: 25px;
  }
  .sale-item img {
    right: 5px;
    max-width: 110px;
  }
  .customiseBtn {
    padding: 11px 9px;
    font-size: 15px;
  }
  .search-icon {
    position: absolute !important;
    left: 232px !important;
    z-index: 999;
    font-size: 13px;
    color: #757575 !important;
  }
  .form-controlTwo {
    position: relative;
    width: 100% !important;
  }
  .deal-area .deal-black {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .toggle-div {
    /* margin-left: 43rem !important; */
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-top-area .right ul li {
    margin-right: 10px;
  }
  .main-nav nav .navbar-nav .nav-item a {
    font-size: 15px;
    margin-left: 6px;
    margin-right: 6px;
    width: 75px;
  }
  .main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
    width: 100%;
  }
  .products-area .sorting-menu ul li {
    margin-right: 5px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 47%;
    flex: 0 0 47%;
    max-width: 47%;
  }
  .products-thumb {
    padding: 15px 5px;
  }
  .products-thumb span {
    font-size: 13px;
  }
  .testimonials-item {
    padding: 0 15px 75px;
  }
  .testimonials-area .owl-prev {
    left: 0;
    top: 88%;
  }
  .testimonials-area .owl-next {
    right: 0;
    top: 88%;
  }
  .blog-item .bottom h3 {
    font-size: 18px;
  }
  .footer-area .footer-shape img:nth-child(2) {
    left: 0;
    bottom: -29px;
  }
  .footer-area .footer-shape img {
    max-width: 60px;
  }
  .sale-item {
    padding: 40px 10px 40px 15px;
  }
  .sale-item img {
    right: 0px;
    max-width: 145px;
  }
  .products-area.two .owl-theme .owl-nav {
    width: 100%;
    padding-bottom: 30px;
    margin-top: -4px;
  }
  .products-area.two .owl-prev {
    position: relative;
    left: 0;
    top: 0%;
  }
  .products-area.two .owl-next {
    position: relative;
    right: 0;
    top: 0%;
  }
  .products-item .top .inner h3 {
    font-size: 14px;
  }
  .buy-area.two .buy-shape img:nth-child(2) {
    max-width: 130px;
  }
  .deal-item .inner .left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }
  .deal-item .inner .right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .testimonials-area.three .testimonials-item {
    padding: 50px 100px 75px;
  }
  .video-area .products-item .top .inner h3 {
    font-size: 16px;
  }
  .products-area.three .products-item .top .inner h3 {
    font-size: 16px;
  }
  .banner-area-two .banner-item .banner-content {
    max-width: 555px;
  }
  .banner-area-two .banner-item .banner-content h1 {
    font-size: 49px;
  }
  .page-title-area .title-img img:nth-child(4) {
    max-width: 115px;
  }
  .about-area .about-content .middle ul li {
    padding: 25px 15px 25px 70px;
    margin-right: 15px;
  }
  .about-area .about-content .middle ul li img {
    position: absolute;
    top: 30px;
    left: 20px;
    max-width: 40px;
  }
  .about-area .about-content .middle ul li h3 {
    font-size: 18px;
  }
  .about-area .about-img img:nth-child(1) {
    padding-left: 0;
  }
  .about-area .about-img img:nth-child(3) {
    max-width: 233px;
    margin-left: 233px;
  }
  .buy-area .buy-shape img:nth-child(1) {
    max-width: 190px;
  }
  .popup-modal.modal-right.show .modal-dialog {
    -webkit-transform: translate(0, 70px);
    transform: translate(0, 70px);
  }
  .blog-details-area
    .details-item
    .details-arrow-page
    .left
    ul
    li:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .blog-details-area
    .details-item
    .details-arrow-page
    .right
    ul
    li:nth-child(1) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .product-details-area .top .top-content h2 {
    font-size: 25px;
  }
  .banner-area .banner-h1 {
    font-size: 60px !important;
  }
  .sale-area {
    margin-top: 97px;
  }
  .header-area .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .css-1nbic85 {
    width: 100% !important;
  }
  .nav-top-area .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-nav .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .banner-area .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav-top-area .right ul li .join {
    padding: 6px 12px;
  }
  .banner-area-two {
    padding-left: 15px;
    padding-right: 15px;
  }
  .banner-area-two .banner-item {
    height: 510px;
  }
  .banner-area-two .banner-item .banner-content {
    max-width: 570px;
  }
  .banner-area-two .banner-item .banner-img img:nth-child(1) {
    top: 50px;
    height: auto;
    max-width: 425px;
  }
  .toggle-div {
    /* margin-left: 60rem !important; */
  }
}
@media only screen and (min-width: 1018px) and (max-width: 1199px) {
  .XNbel {
    font-size: 1rem;
    background: transparent;
    color: #fff!important;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-left: -160px !important;
  }
  .idRPHM {
    font-size: 1.1rem;
    color: #fff!important;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    left: -211px !important;
  }
  .iiNqQG {
    font-size: 1rem;
    background: transparent;
    color: #fff!important;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-left: -194px !important;
  }
  .sale-item .inner h3 {
    font-size: 22px;
  }
  .dealerContainer {
    padding: 60px 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .iiNqQG {
    
    margin-left: -188px !important;
  }
  .eRWlqt {
    margin-left: 73rem !important
  }
  .fVQvsp {
    margin-left: 70rem !important ;

}
.kXyppV {
  margin-left: 73rem !important;
}

.XNbel {
  font-size: 1rem;
  background: transparent;
  color: #fff!important;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: -197px !important;
}
  .testimonials-area .owl-prev {
    left: -25px;
    border: 1px solid #2ec1ac !important;
  }
 
  .testimonials-area .owl-next {
    right: -25px;
    border: 1px solid #2ec1ac !important;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1800px) {
  .idRPHM {
    font-size: 1.1rem;
    color: #fff!important;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    left: -255px !important;
  }
  .eRWlqt {
    margin-left: 90% !important;
  }
  .fVQvsp {
    margin-left: 74rem !important;
    font-size: 1.5rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .mainDivCustomise {
    margin-left: 206px;
  }
  /* .fxSFUJ {
    margin-left: 84rem !important;
  } */
  .search-icon{
    left:295px !important;
  }
  .fVQvsp {
    margin-left: 98rem !important;
  }
  .XNbel {
    font-size: 1rem;
    background: transparent;
    color: #fff!important;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-left: -249px !important;
  }
 
  .iiNqQG {
    margin-left: -199px !important;
  }
  .kXyppV {
    margin-left: 76rem !important;
  }
}
@media only screen and (min-width: 1600px) {
  .fxSFUJ {
    margin-left: 96rem !important;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1440px) {
  .fxSFUJ {
    margin-left: 84rem !important;
  }
  .kXyppV{
    margin-left: 86rem !important;
  }
}

@media only screen and (min-width: 1800px) {
  .footer-area .footer-shape img {
    max-width: 100%;
  }
  .buy-area .buy-shape img:nth-child(1) {
    max-width: 100%;
  }
  .sale-item img {
    max-width: 180px;
  }
  .buy-area.two .buy-shape img:nth-child(1) {
    max-width: 100%;
  }
  .buy-area.two .buy-shape img:nth-child(2) {
    max-width: 100%;
  }
  .page-title-area .title-img img:nth-child(4) {
    max-width: 305px;
  }
  .header-area .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav-top-area .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-nav .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .banner-area .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .banner-area .banner-h1 {
    font-size: 80px !important;
  }
  .banner-area-two {
    padding-left: 115px;
    padding-right: 115px;
  }
  .banner-area-two .banner-item .banner-content {
    max-width: 790px;
  }
  .banner-area-two .banner-item .banner-content h1 {
    font-size: 70px;
  }
  .banner-area-two .banner-item .banner-content p {
    max-width: 700px;
  }
  .banner-area-two .banner-item .banner-img img:nth-child(1) {
    max-width: 650px;
  }
}
@media only screen and (max-width: 991px) {
  .mean-container .mean-bar {
    background-color: #fff;
  }
  .mean-container a.meanmenu-reveal {
    color: #434e6e;
  }
  .mean-container a.meanmenu-reveal span {
    margin-top: 30px;
    position: relative;
    top: -10px;
    background-color: #434e6e;
    border-radius: 0%;
  }
  .mobile-nav .logo {
    top: 8px;
    max-width: 95px;
  }
  .mobile-nav.mean-container .mean-nav ul li a.active {
    color: #f05454;
  }
  .navbar-nav {
    height: 320px;
    overflow-y: scroll;
  }
}


@media only screen and (min-width: 374px) and (max-width: 400px) {
  .css-187mznn-MuiSlider-root {
    width: 100% !important;
  }
  .totalword{
    font-weight:100;
    width:39%;
    text-align: right;
  }
  .totalamount{
    font-size: 14px;
    width: 70%;
    margin-top: 2px;
  }
  .checkout-responsive{
    margin-left:65px
  }
  .shopingDiv{
    flex-direction:column-reverse;
  }
  .mainDivContinueCart{
    width: 100%;
  }
  .account-content{
    overflow-x: scroll;
    
  }
  .cartTitle{
    font-size:12px;
    text-align:center;
  }
  .cart-mobileresponsive{
    font-size: 13px;
  }

  .customiseBtn {
    padding: 11px 9px;
    font-size: 9px;
  }
  .css-1nbic85 {
    width: 100% !important;
  }
  .customiseBtn {
    padding: 10px 61px;
    font-size: 8px;
  }
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .XNbel{
    margin-left: -233px !important;
  }
  .idRPHM {
    font-size: 1.1rem;
    color: #fff!important;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    left: -242px !important
  }
  .skateresp{
    margin-top: -1px;
    margin-left: -17px;
  }
  .dashboard_content h3{
    font-size:15px;
    text-align: center;
  }
  deal-area .deal-black h3{
    font-size:10px !important
  }
  .cart-num{
    font-size:13px
  }
  .product-details-area #thumbs.owl-carousel.owl-drag .owl-item {
    max-width: 135px;
  }
  .ptb-100{
    padding-bottom:0px;
  }
  .deal-area .deal-black {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .account-content{
    overflow-x: scroll;
    
  }
  .cart-mobileresponsive{
    font-size: 13px;
  }

  .customiseBtn {
    padding: 11px 9px;
    font-size: 9px;
  }
  .css-1nbic85 {
    width: 100% !important;
  }
  .customiseBtn {
    padding: 10px 61px;
    font-size: 8px;
  }
  .toggle-div {
    /* margin-left: 23rem !important; */
  }
  .nav-main-div {
    width: 320px !important;
  }
  .nav-logo {
    padding: 10px !important;
    width: 110px !important;
  }
  .search-icon {
    left: 352px !important;
    z-index: 1;
  }
  .acrodingContent {
    display: flex !important;
    gap: 15px;
  }

  .form-controlTwo {
    position: relative;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 40px !important;
    width: 100% !important;
  }
  .help-btn-div {
    margin-bottom: 15px;
  }
  .close-btn {
    margin-left: 18rem !important;
  }
  .mainDivCustomise {
    gap: 4px !important;
  }
  .need-help-div {
    margin: 0px 5px !important;
    margin-right: 0px !important;
  }
  .need-help-span {
    font-size: 15px;
  }
  .btn-light {
    padding: 4px;
  }
  .shopingDiv{
    flex-direction:column-reverse;
  }
  .mainDivContinueCart{
    width: 100%;
  }
  .cartTitle{
    font-size:12px;
    text-align:center;
  }
  .totalword{
    font-weight:100;
    width:39%;
    text-align: right;
  }
  .totalamount{
    font-size: 14px;
    width: 70%;
    margin-top: 2px;
  }
  .checkout-responsive{
    margin-left:65px
  }
  .inner a{
    font-size: 15px;
  }
  .inner{
    margin:0 auto;
  }
  .checkout-responsive a{
    font-size:15px;
  }
  .addcheckoutres{
    margin:0 auto;
  }
  .css-187mznn-MuiSlider-root{
    width: 100% !important;
  }
  .nameresponsive{
    font-size: 14px;
  }
  .cart-num{
    padding:0 3px
  }

  .buy-content h2{
   font-size:15px !important;
   margin-left:-29px
  }
  .buy-content p{
    font-size: 18px !important;
    margin-left: -17px;
    margin-top:3px
  }
  .common-btn{
    margin-left:-1px
  }
}
@media only screen and (min-width:0px) and (max-width:400px){
  .account-content{
    overflow-x: scroll;
    
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .search-icon {
    left: 220px !important;
  }
  .css-187mznn-MuiSlider-root{
    width: 89% !important;
  }
  /* .figureContent {
    height: auto;
  } */
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .iHuAPa {
    margin-left: 85rem !important;
  }
  /* .search-icon {
    left: 372px !important;
  } */
}

@media (min-width: 1800px) and (max-width: 2559px) {
  .nav-logo {
    width: 300px !important;
    padding: 0px 30px !important;
  }
  .toggle-btn {
    margin-left: 16rem !important;
  }
  .toggle-div {
    /* margin-left: 96rem !important; */
    font-size: 2rem !important;
  }
  .login-sign-btn {
    font-size: 1.4rem !important;
    margin-left: -236px !important;
  }
  .nav-cart {
    font-size: 1.5rem !important;
    left: -255px !important;
  }
  .form-controlTwo {
    height: 49px !important;
  }
  .search-icon {
    left: 431px !important;
  }
  .need-help-div {
    font-size: 20px !important;
  }
  .help-btn-div {
    justify-content: end !important;
    gap: 30px !important;
  }
}

@media (max-width: 425px) {
  .product-group-card {
    width: auto !important;
  }
  h2{
  font-size: calc(1.325rem);
  }
  .cartMainDiv{
    gap: 10px !important;
  }
}

@media (max-width: 375px) {
  .frontpage-resp{
    /* margin-left: -160px !important; */
    
  }
  .nav-cart{
    /* left: -19% !important; */
  }
  .nav-logo {
    padding: 10px !important;
    width: 150px !important;
}
.help-btn-div{
  margin-bottom: 12px;
}

}
@media (max-width: 361px) {
  .frontpage-resp{
    /* margin-left: -150px !important; */
    
  }
  /* .nav-cart{
    left: -100px !important;
  } */
  .nav-logo {
    padding: 10px !important;
    width: 150px !important;
}
.help-btn-div{
  margin-bottom: 12px;
  
}
#mini-mobile-toggle-div {
  /* margin-left: 18rem !important; */
}
.close-btn{
  margin-left: 17rem !important;
}
.nav-main-div {
  width: 320px !important;
}
}

@media (min-width: 425px) and (max-width: 431px) {
  .product-group-card {
    width: 170px !important;
  }
}