@import "https://fonts.googleapis.com/css2?family=Lato:wght@400;700&amp;display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&amp;display=swap";
body {
  font-family: lato, sans-serif;
  color: #545454;
  background-color: #fff;
  font-size: 16px;
  background-color: #f5f5f5;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #000;
  font-family: poppins, sans-serif;
}
p {
  line-height: 2;
  margin-bottom: 0rem !important;
}
a {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
}
img {
  max-width: 100%;
  object-fit: contain;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}
/* Loader Css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.mtb-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-100 {
  margin-bottom: 100px;
}
.ptb-100 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.pt-100 {
  padding-top: 50px;
}
.viewLink {
  color: #000;
}
.descriptionDiv {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #000;
}
.pb-70 {
  padding-bottom: 50px;
}
.pb-100 {
  padding-bottom: 50px;
}
button:focus {
  outline: 0;
}
.btn.focus,
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.container {
  max-width: 1160px;
}
.css-1nbic85 {
  width: 130px !important;}
  
.common-btn {
  display: inline-block;
  color: #fff;
  background-color: #000;
  padding: 12px 30px;
  font-weight: 600;
  border: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 1 !important;
  z-index: 1;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.common-btn img {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: -1;
}
.common-btn img:nth-child(1) {
  bottom: -15px;
  left: -15px;
}
.common-btn img:nth-child(2) {
  top: -15px;
  right: -15px;
}

.common-btn:hover {
  background: #df5c5c;
  color: #fff;
}
.resetLink {
  color: #000;
}
.resetPasswordDiv {
  padding: 15px 0px;
}
.error {
  color: red;
}
.common-btn:hover img {
  opacity: 0.2;
  visibility: visible;
  max-width: 30px;
}
.common-btn:hover img:nth-child(1) {
  bottom: 0;
  left: 0;
}
.common-btn:hover img:nth-child(2) {
  top: 0;
  right: 0;
}
/* .common-btn.two {
  background-color: #000;
} */
.common-btn.three {
  background-color: #ff5f59;
}
.section-title {
  margin-bottom: 30px;
  margin-top: -5px;
}
.section-title h2 {
  color: #000;
  font-size: 25px;
  margin-bottom: 0;
}
.header-area {
  background-color: #434e6e;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  z-index: 999;
}
.header-area .container-fluid {
  max-width: 1720px;
  padding-left: 50px;
  padding-right: 50px;
}
.header-area .left ul {
  margin: 0;
  padding: 0;
}
.header-area .left ul li {
  list-style-type: none;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  margin-right: 15px;
}
.header-area .left ul li i {
  display: inline-block;
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 3px;
}
.header-area .right {
  text-align: right;
}
.header-area .right .inner {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
.header-area .right .inner:last-child {
  margin-right: 0;
}
.header-area .right .inner .nice-select {
  height: 35px;
  border-radius: 5px;
  line-height: 35px;
  color: #545454;
  background-color: #fff;
  border: 0;
}
.header-area .right .inner .nice-select:after {
  right: 20px;
  top: 52%;
}
.header-area .right .inner .call i {
  display: inline-block;
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 3px;
  color: #fff;
}
.header-area .right .inner .call a {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  position: relative;
}
.header-area .right .inner .call a:before {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.header-area .right .inner .call a:hover:before {
  width: 100%;
}
.nav-top-area {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
  z-index: 99;
}
.nav-top-area .container-fluid {
  max-width: 1720px;
  padding-left: 50px;
  padding-right: 50px;
}
.nav-top-area .left a {
  display: inline-block;
}
.nav-top-area .middle .form-group {
  position: relative;
}
.nav-top-area .middle .form-group .inner {
  position: absolute;
  top: 6px;
  left: 10px;
}
.nav-top-area .middle .form-group .inner .nice-select {
  border: 0;
  background-color: #434e6e;
  border-radius: 5px;
  height: 45px;
  line-height: 45px;
  color: #fff;
  font-size: 16px;
  width: 145px;
  padding-left: 15px;
  padding-right: 20px;
}
.nav-top-area .middle .form-group .inner .nice-select:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.nav-top-area .middle .form-group .inner .nice-select .option {
  color: #545454;
  font-size: 15px;
}
.form-group {
  margin-bottom: 15px;
}
.addressTitle {
  padding: 8px;
  background: #000;
  color: #fff;
  border-radius: 7px;
}
.addBox {
  border: 1px dashed #000;
  padding: 20px;
  margin: 15px 0px;
}
.iconBox {
  text-align: center;
}
.nav-top-area .middle .form-group .form-control {
  height: 60px;
  border-radius: 5px;
  /* -webkit-box-shadow: 0 0 20px 0 #dcdcdc; */
  box-shadow: 0 0 20px 0 #dcdcdc;
  border: 1px solid transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding-left: 50px;
}
.nav-top-area .middle .form-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #434e6e;
  box-shadow: none;
}
.nav-top-area .middle .form-group .btn {
  position: absolute;
  top: 8px;
  right: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0;
  font-size: 30px;
  color: #545454;
  padding-right: 15px;
}
.nav-top-area .middle .form-group .btn:hover {
  color: #f05454;
}
.nav-top-area .right {
  text-align: right;
}
.nav-top-area .right ul {
  margin: 0;
  padding: 0;
}
.nav-top-area .right ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
}
.nav-top-area .right ul li:last-child {
  margin-right: 0;
}
.nav-top-area .right ul li .inner i {
  display: inline-block;
  font-size: 18px;
  margin-right: 2px;
}
.nav-top-area .right ul li .inner a {
  display: inline-block;
  color: #000;
  font-weight: 600;
}
.nav-top-area .right ul li .inner a:hover {
  color: #f05454;
}
.nav-top-area .right ul li .wishlist {
  color: #545454;
  font-weight: 600;
  font-size: 25px;
  position: relative;
  display: inline-block;
  top: 2px;
  vertical-align: middle;
  padding: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.nav-top-area .right ul li .wishlist span {
  display: inline-block;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: -4px;
  color: #fff;
  background-color: #f05454;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  border-radius: 50%;
}
.nav-top-area .right ul li .wishlist:hover {
  color: #f05454;
}
.nav-top-area .right ul li .join {
  display: inline-block;
  color: #fff;
  background-color: #434e6e;
  padding: 8px 18px;
  border-radius: 5px;
  border: 1px solid #434e6e;
}
.nav-top-area .right ul li .join i {
  display: inline-block;
  font-size: 16px;
  position: relative;
  top: 1px;
  margin-right: 2px;
}
.nav-top-area .right ul li .join:hover {
  color: #434e6e;
  background-color: #fff;
}
.sticky-top {
  z-index: 9;
}
.main-nav {
  background: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.main-nav.menu-shrink {
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-shadow: 0 0 20px 0 #dddddd59;
  box-shadow: 0 0 20px 0 #dddddd59;
  z-index: 9999999;
}
.main-nav .container-fluid {
  max-width: 1720px;
  padding-left: 50px;
  padding-right: 50px;
}
.main-nav .left .nice-select {
  border: 0;
  background-color: #fff;
  border-radius: 5px;
  height: 45px;
  line-height: 45px;
  color: #363636;
  font-size: 16px;
  /* width: 145px; */
  padding-left: 15px;
  padding-right: 20px;
  -webkit-box-shadow: 0 0 20px 0 #dddddd59;
  box-shadow: 0 0 20px 0 #dddddd59;
}
.main-nav .left .nice-select li {
  font-size: 15px;
}
.main-nav nav .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}
.main-nav nav .navbar-nav .nav-item:hover .dropdown-menu:before {
  width: 45px;
}
.main-nav nav .navbar-nav .nav-item:hover .dropdown-menu:after {
  height: 45px;
}
.main-nav nav .navbar-nav .nav-item:hover a {
  color: #363636;
}
.main-nav nav .navbar-nav .nav-item a {
  font-weight: 600;
  font-size: 16px;
  text-transform: unset;
  color: #363636;
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.main-nav nav .navbar-nav .nav-item a:before {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  left: 0;
  bottom: -2px;
  background-color: #363636;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.main-nav nav .navbar-nav .nav-item a:hover,
.main-nav nav .navbar-nav .nav-item a:focus,
.main-nav nav .navbar-nav .nav-item a.active {
  color: #363636;
}
.main-nav nav .navbar-nav .nav-item a:hover:before,
.main-nav nav .navbar-nav .nav-item a:focus:before,
.main-nav nav .navbar-nav .nav-item a.active:before {
  width: 40%;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu {
  background: #fff;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  border: 0;
  border-radius: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 260px;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu:before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  top: 0;
  left: 0;
  background-color: #363636;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 0;
  top: 0;
  left: 0;
  background-color: #363636;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li {
  border-bottom: 1px solid #f1f0f0;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  top: 0 !important;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a {
  color: #363636;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: unset;
  padding: 12px 15px;
  color: #363636;
  font-weight: 600;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:before {
  display: none;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:focus,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #f05454;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: unset;
  right: -100%;
  top: 15px;
}
.main-nav
  nav
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li:hover
  a {
  color: #363636;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #363636;
  text-transform: unset;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.main-nav
  nav
  .navbar-nav
  .nav-item
  .dropdown-menu
  li
  .dropdown-menu
  li
  a.active {
  color: #f05454;
}
.main-nav .dropdown-toggle::after {
  display: none;
}
.side-nav h4 {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  color: #545454;
  font-family: lato, sans-serif;
}
.side-nav h4 span {
  display: inline-block;
  color: #f05454;
  font-weight: 600;
}
.side-nav h4 a {
  display: inline-block;
  color: #434e6e;
  border-bottom: 1px solid #434e6e;
  font-weight: 600;
}
.side-nav h4 a:hover {
  color: #f05454;
  border-bottom: 1px solid #f05454;
}
.banner-area .container-fluid {
  max-width: 1720px;
  padding-left: 50px;
  padding-right: 50px;
}
.banner-area .banner-h1 {
  font-size: 74px !important;
  color: #434e6e !important;
  font-weight: 700 !important;
  font-family: poppins, sans-serif;
  line-height: 1.2 !important;
}
.banner-area .banner-p {
  font-family: lato, sans-serif;
  font-size: 18px !important;
  line-height: 1.6 !important;
}
.banner-area .banner-btn:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #363636;
  border-radius: 50% 50% 0 0;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.banner-area .banner-btn:hover:before {
  height: 100%;
  border-radius: 5px;
}
.banner-area .tp-leftarrow {
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 20px 0 #dddddd59;
  box-shadow: 0 0 20px 0 #dddddd59;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: matrix(1, 0, 0, 1, -50, -125) !important;
  transform: matrix(1, 0, 0, 1, -50, -125) !important;
}
.banner-area .tp-leftarrow:before {
  color: #434e6e;
}
.banner-area .tp-leftarrow:hover {
  background: #434e6e;
}
.banner-area .tp-leftarrow:hover:before {
  color: #fff;
}
.banner-area .tp-rightarrow {
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 20px 0 #dddddd59;
  box-shadow: 0 0 20px 0 #dddddd59;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: matrix(1, 0, 0, 1, 10, -125) !important;
  transform: matrix(1, 0, 0, 1, 10, -125) !important;
}
.banner-area .tp-rightarrow:before {
  color: #434e6e;
}
.banner-area .tp-rightarrow:hover {
  background: #434e6e;
}
.banner-area .tp-rightarrow:hover:before {
  color: #fff;
}
#Container .mix {
  display: none;
}
.products-area {
  background-color: #fff;
  overflow: hidden;
  padding-top: 50px;
}
.products-area .sorting-menu ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.products-area .sorting-menu ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  -webkit-box-flex: 0;
  border-radius: 10px;
  -ms-flex: 0 0 46%;
  flex: 0 0 46%;
  max-width: 46%;
  cursor: pointer;
  box-shadow: 1px 1px 8px 1px #b5b5b5;
}
.products-area .sorting-menu ul li.active .products-thumb {
  background-color: #000;
}
.products-area .sorting-menu ul li.active .products-thumb img {
  opacity: 0.2;
  visibility: visible;
}
.products-area .sorting-menu ul li.active .products-thumb img:nth-child(1) {
  left: 0;
  bottom: 0;
}
.products-area .sorting-menu ul li.active .products-thumb img:nth-child(2) {
  top: 0;
  right: 0;
}
.products-area .sorting-menu ul li.active .products-thumb i {
  color: #fff;
}
.products-area .sorting-menu ul li.active .products-thumb span {
  color: #fff;
}
.products-thumb {
  padding: 5px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px 0 #dddddd59;
  box-shadow: 0 0 20px 0 #dddddd59;
  text-align: center;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
  height: 132px;
  color: #000;
  /* max-width: 150px; */
}
.products-thumb:hover {
  background-color: #57cdbc;
}
.manDivPriceRangeTitle {
  padding: 10px;
  border-radius: 7px;
  background-color: #ddef77;
  /* margin-bottom: 20px; */

  height: 60px;
  /* overflow-y: auto; */
}
.myProfileIcon {
  height: 111px !important;
}
.selctSection {
  --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
  display: block;
  width: 100%;
  padding: 5px 2.25rem 4px 0.75rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}
.priceTitle {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 7px;
}
.filterCol {
  margin-bottom: 15px;
}
.brandBackground {
  background-color: #f24845;
}

.checkBoxTitle {
  margin-left: 10px;
}
.brandTitleDiv {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
}
.products-thumb:hover img {
  opacity: 0.2;
  visibility: visible;
}
.products-thumb:hover img:nth-child(1) {
  left: 0;
  bottom: 0;
}
.products-thumb:hover img:nth-child(2) {
  top: 0;
  right: 0;
}
.products-thumb:hover i {
  color: #fff;
}
.products-thumb:hover span {
  color: #fff;
}
.products-thumb img {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.products-thumb img:nth-child(1) {
  left: -15px;
  bottom: -15px;
}
.products-thumb img:nth-child(2) {
  top: -15px;
  right: -15px;
}
.products-thumb i {
  display: block;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #57cdbc;
}
.products-thumb span {
  display: block;
  font-weight: 600;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 13px;
  color: #000;
}

.products-item {
  background-color: #fff;
  border-radius: 10px;
  /* margin-bottom: 30px; */
  padding-bottom: 40px;
  position: relative;
  text-align: center;
  transition: 0.5s;
  box-shadow: 0px 0px 10px 0px #6e6e6e;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.slick .products-item {
  background-color: #fff;
  border-radius: 10px;
  /* margin-bottom: 30px; */
  padding-bottom: 40px;
  position: relative;
  text-align: center;
  transition: 0.5s;
  box-shadow: 0px 0px 10px 0px #6e6e6e;
  width: 95%;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
  margin-top: 20px;
}
.imgProduct {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}
.products-item:hover {
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}
/* .products-item:hover .top img {
  -webkit-transform: scale(1.1) rotate(10deg);
  transform: scale(1.1) rotate(10deg);
} */
.products-item .top {
  position: relative;
  /* padding-top: 35px; */
}
.products-item .top .product-type {
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  padding: 3px 12px;
  font-size: 14px;
  background-color: #ffde00;
  position: absolute;
  top: 15px;
  left: 15px;
}
.products-item .top .product-type.two {
  background-color: #ff0f4e;
}
.products-item .top .wishlist {
  display: inline-block;
  color: #f05454;
  font-size: 25px;
  position: absolute;
  top: 14px;
  right: 15px;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px 0 #dddddd59;
  box-shadow: 0 0 20px 0 #dddddd59;
  border-radius: 50%;
}
.products-item .top .wishlist i {
  line-height: 40px;
}
.products-item .top .wishlist:hover {
  color: #fff;
  background-color: #f05454;
}
.products-item .top img {
  max-width: 180px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.products-item .top .inner {
  text-align: left;
  padding-left: 15px;
}
.products-item .top .inner h3 {
  margin-bottom: 5px;
  font-size: 16px;
}
.products-item .top .inner h3 a {
  color: #363636;
}
.wishListImg {
  width: 100px;
}
.products-item .top .inner h3 a:hover {
  color: #f05454;
}
.products-item .top .inner span {
  color: #57cdbc;
  display: block;
  font-size: 18px;
  font-weight: 700;
}
.cuponLink {
  border: 2px dotted red;
  background: #000;
  color: #fff;
  padding: 10px;
  border-radius: 7px;
}
.products-item .bottom {
  text-align: right;
  position: absolute;
  right: -8px;
  bottom: -8px;
}
.products-item .bottom:hover a {
  opacity: 1;
  visibility: visible;
  right: 15px;
}
.products-item .bottom:hover i {
  color: #fff;
  background-color: #57cdbc;
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}
.products-item .bottom a {
  display: inline-block;
  color: #57cdbc;
  font-weight: 600;
  position: relative;
  top: -5px;
  right: 0;
  opacity: 0;
  visibility: hidden;
}
.products-item .bottom a:hover {
  color: #f05454;
}
.products-item .bottom i {
  background-color: #fff;
  border: 10px solid #f5f5f5;
  border-radius: 50%;
  color: #57cdbc;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  height: 60px;
  line-height: 35px;
  text-align: center;
  transition: 0.5s;
  width: 60px;
}
.buy-area {
  position: relative;
  overflow: hidden;
  background-color: #fff;
}
.buy-area .buy-shape img {
  position: absolute;
}
/* .buy-shape {
  filter: hue-rotate(133deg);
} */
.buy-area .buy-shape img:nth-child(1) {
  right: 0;
  bottom: 0;
  max-width: 300px;
  -webkit-animation: buy-ani-one 8s infinite linear;
  animation: buy-ani-one 8s infinite linear;
}
.buy-area .buy-content {
  max-width: 500px;
  padding-left: 30px;
}
.buy-area .buy-content h2 {
  font-size: 50px;
  margin-bottom: 10px;
  line-height: 1.3;
  margin-top: -10px;
}
.buy-area .buy-content p {
  color: #363636;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 40px;
}
.buy-area .buy-content ul {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.buy-area .buy-content ul li {
  color: #41a74e;
  display: inline-block;
  font-size: 28px;
  font-weight: 800;
  list-style-type: none;
  margin-right: 15px;
}
.buy-area .buy-content ul li:nth-child(2) {
  font-weight: 400 !important;
  font-size: 15px !important;
  color: grey !important;
}
@-webkit-keyframes buy-ani-one {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(50px, 0);
    transform: translate(50px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes buy-ani-one {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(50px, 0);
    transform: translate(50px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.brand-area {
  background-color: #eff1ff;
}
.brand-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 8px 1px #b5b5b5;
  margin-bottom: 30px;
  padding-bottom: 25px;
  padding-top: 25px;
  text-align: center;
  transition: 0.5s;
}
.brand-item a {
  display: block;
}
.brand-item:hover {
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.testimonials-area {
  background-color: #f5f5f5;
}
.testimonials-area .owl-theme .owl-nav {
  margin-top: 0;
}
.testimonials-area .owl-prev {
  position: absolute;
  left: -70px;
  top: 40%;
  width: 45px;
  height: 45px;
  line-height: 49px !important;
  border-radius: 10px !important;
  color: #434e6e !important;
  background-color: #fff !important;
  font-size: 25px !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.testimonials-area .owl-prev:hover {
  color: #fff !important;
  background-color: #434e6e !important;
}
.testimonials-area .owl-next {
  position: absolute;
  right: -70px;
  top: 40%;
  width: 45px;
  height: 45px;
  line-height: 49px !important;
  border-radius: 10px !important;
  color: #434e6e !important;
  background-color: #fff !important;
  font-size: 25px !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.testimonials-area .owl-next:hover {
  color: #fff !important;
  background-color: #434e6e !important;
}
.testimonials-area .owl-item.center .testimonials-item {
  background-color: #434e6e;
}
.testimonials-area .owl-item.center .testimonials-item i {
  color: #fff;
}
.testimonials-area .owl-item.center .testimonials-item p {
  color: #fff;
}
.testimonials-area .owl-item.center .testimonials-item h3 {
  color: #fff;
}
.testimonials-area .owl-item.center .testimonials-item span {
  color: #fff;
}
.testimonials-item {
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  padding: 0 35px 35px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;

  margin: 0 8px;
}
.testimonials-item:hover {
  background-color: #434e6e;
}
.testimonials-item:hover i {
  color: #fff;
}
.testimonials-item:hover p {
  color: #fff;
}
.testimonials-item:hover h3 {
  color: #fff;
}
.testimonials-item:hover span {
  color: #fff;
}
.testimonials-item i {
  display: inline-block;
  font-size: 65px;
  margin-bottom: 15px;
  line-height: 65px;
  margin-top: -8px;
  color: #434e6e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.testimonials-item p {
  margin-bottom: 16px;
  font-size: 18px;
  font-style: italic;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.testimonials-item h3 {
  margin-bottom: 5px;
  font-size: 20px;
  color: #363636;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.testimonials-item span {
  display: block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.testimonials-item img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  max-width: 110px;
  margin-left: auto;
  margin-right: auto;
  border: 10px solid #e7e6e6;
  border-radius: 50%;
}
.support-item {
  margin-bottom: 30px;
  position: relative;
  background-color: #434e6e;
  border-radius: 10px;
  padding: 30px 15px 30px 60px;
  margin-left: 45px;
  z-index: 1;
}
.support-item:hover i {
  border: 10px solid #434e6e;
  color: #434e6e;
  background-color: #fff;
}
.support-item i {
  display: inline-block;
  width: 90px;
  height: 90px;
  text-align: center;
  line-height: 70px;
  font-size: 30px;
  color: #fff;
  background-color: #434e6e;
  border: 10px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  left: -45px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.support-item h3 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 20px;
}
.support-item p {
  margin-bottom: 0;
  color: #fff;
}
.support-item img {
  position: absolute;
  right: 10px;
  bottom: 0;
  max-width: 125px;
  z-index: -1;
}
.blog-area {
  background-color: #f5f5f5;
}
.blog-item {
  margin-bottom: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: white;
  padding: 10px;
  border-radius: 10px;
}
.blog-item:hover {
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.blog-item:hover .top span {
  color: #fff;
  background-color: #434e6e;
  border: 7px solid #fff;
}
.blog-item .top {
  position: relative;
  margin-bottom: 25px;
  border-radius: 10px;
}
.blog-item .top a {
  display: block;
}
.blog-item .top a img {
  width: 100%;
  border-radius: 10px;
}
.blog-item .top span {
  display: inline-block;
  width: 85px;
  height: 85px;
  padding: 11px 15px 0;
  text-align: center;
  color: #363636;
  background-color: #fff;
  border: 7px solid #f5f5f5;
  font-weight: 600;
  border-radius: 50%;
  position: absolute;
  bottom: -20px;
  right: -4px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.blog-item .bottom h3 {
  line-height: 1.4;
  font-size: 20px;
  margin-bottom: 10px;
}
.blog-item .bottom h3 a {
  color: #363636;
}
.blog-item .bottom h3 a:hover {
  color: #434e6e;
}
.blog-item .bottom p {
  margin-bottom: 14px;
}
.blog-item .bottom .blog-btn {
  display: inline-block;
  font-weight: 600;
  color: #363636;
}
.blog-item .bottom .blog-btn:hover {
  color: #434e6e;
}
.blog-item .bottom .blog-btn:hover i {
  -webkit-animation: blog-rotate-ani 2s infinite linear;
  animation: blog-rotate-ani 2s infinite linear;
}
.blog-item .bottom .blog-btn i {
  display: inline-block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 17px;
  position: relative;
  top: 0px;
  left: 5px;
}
@-webkit-keyframes blog-rotate-ani {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes blog-rotate-ani {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.footer-area {
  position: relative;
  z-index: 1;
  background-color: #fff;
  /* background-image: url("../assets/img/footerBg.png"); */
  background-size: cover;
}
.footer-area .footer-shape img {
  position: absolute;
  z-index: -1;
  max-width: 105px;
}
.footer-area .footer-shape img:nth-child(1) {
  top: 0;
  right: 0;
}
.footer-area .footer-shape img:nth-child(2) {
  left: 0;
  bottom: 30px;
}
.footer-item {
  margin-bottom: 30px;
}
.footer-item h3 {
  font-size: 20px;
  margin-bottom: 25px;
}
.footer-item .footer-logo .logo {
  display: block;
  margin-bottom: 30px;
}
.footer-item .footer-logo ul {
  margin: 0;
  padding: 0;
}
.footer-item .footer-logo ul li {
  list-style-type: none;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
}
.footer-item .footer-logo ul li:last-child {
  margin-bottom: 0;
}
.footer-item .footer-logo ul li i {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
}
.footer-item .footer-logo ul li a {
  display: block;
  color: #545454;
}
.footer-item .footer-logo ul li a:hover {
  color: #f05454;
}
.footer-item .footer-services ul {
  margin: 0;
  padding: 0;
}
.footer-item .footer-services ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 12px;
}
.footer-item .footer-services ul li:last-child {
  margin-bottom: 0;
}
.footer-item .footer-services ul li a {
  display: block;
  color: #545454;
}
.footer-item .footer-services ul li a:hover {
  color: #f05454;
  padding-left: 5px;
}
.footer-item .footer-links ul {
  margin: 0;
  padding: 0;
}
.footer-item .footer-links ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 12px;
}
.footer-item .footer-links ul li:last-child {
  margin-bottom: 0;
}
.footer-item .footer-links ul li a {
  display: block;
  color: #545454;
}
.footer-item .footer-links ul li a:hover {
  color: #f05454;
  padding-left: 5px;
}
.payment-cards {
  margin-bottom: 30px;
}
.payment-cards ul {
  margin: 0;
  padding: 0;
}
.payment-cards ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;
}
.payment-cards ul li a {
  display: block;
  max-width: 50px;
}
.payment-cards ul li a:hover {
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}
.social-links {
  text-align: right;
  margin-bottom: 30px;
}
.social-links ul {
  margin: 0;
  padding: 0;
}
.social-links ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 5px;
}
.social-links ul li a {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  background-color: gray;
  font-size: 16px;
  text-align: center;
}
.mainSectionProductDetails {
  padding-top: 60px;
}
.social-links ul li a i {
  line-height: 30px;
}
.social-links ul li a:hover {
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}
.copyright-area {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #434e6e;
}
.copyright-area .copyright-item {
  text-align: center;
}
.copyright-area .copyright-item p {
  margin-bottom: 0;
  color: #fff;
}
.copyright-area .copyright-item p a {
  display: inline-block;
  color: #fff;
  font-weight: 700;
}
.copyright-area .copyright-item p a:hover {
  color: #000;
}
.popup-modal {
  z-index: 999999;
}
.popup-modal .modal-header {
  display: block;
  padding: 0;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 25px;
}
.popup-modal .modal-header h2 {
  font-size: 22px;
  margin-bottom: 0;
  position: relative;
  margin-top: -4px;
}
.popup-modal .modal-header h2 span {
  display: inline-block;
  font-size: 16px;
  position: absolute;
  top: 4px;
  right: 0;
}
.popup-modal .btn-close {
  position: absolute;
  top: -13px;
  right: 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 12px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 3px solid #ddd;
  background-color: #fff;
  color: #363636;
  padding: 0;
  opacity: 1;
}
.popup-modal .btn-close:hover {
  border: 3px solid #434e6e;
}
.popup-modal .btn-close:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.popup-modal .modal-content {
  padding: 30px;
  overflow-y: auto;
  border-radius: 10px 0 0 10px;
  overflow: visible;
}
.popup-modal .modal-body {
  padding: 0;
}
.popup-modal .modal-body .cart-table .table {
  margin-bottom: 30px;
}
.popup-modal .modal-body .cart-table .table tr {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #f4f4f4;
}
.popup-modal .modal-body .cart-table img {
  max-width: 95px;
  margin-left: auto;
  margin-right: auto;
}
.popup-modal .modal-body .cart-table h3 {
  font-size: 16px;
  margin-bottom: 3px;
}
.incrementBtn {
  width: 30px;
  background: #fff;
  color: #000;
  border: none;
}

.incrementBtn:hover {
  background: red;
  color: #fff;
}

/* add cart--> */

.cart-sidebar {
  position: fixed;
  top: 0px;
  right: -450px;
  width: 400px;
  height: 100vh;
  z-index: 5;
  background: var(--white);
  -webkit-box-shadow: -15px 0px 25px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -15px 0px 25px 0px rgba(0, 0, 0, 0.15);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.cart-sidebar.active {
  right: 0px;
}

.cart-header {
  padding: 18px 25px;
  text-align: center;
  position: relative;
  border-bottom: 1px solid var(--border);
}

.cart-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cart-total i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--primary);
}

.cart-total span {
  font-weight: 500;
  color: var(--primary);
  text-transform: capitalize;
}

.cart-close {
  position: absolute;
  top: 50%;
  left: -18px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cart-close i {
  width: 35px;
  height: 35px;
  font-size: 18px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: var(--text);
  background: var(--white);
  text-shadow: var(--primary-tshadow);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.cart-close i:hover {
  color: var(--white);
  background: var(--primary);
}

.cart-list {
  height: 100%;
  padding: 0px 15px;
  max-height: calc(100vh - 210px);
  overflow-y: scroll;
}

.cart-item {
  padding: 15px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid gray;
}

.cart-item:hover .cart-media button {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-media {
  position: relative;
  margin-right: 25px;
}

.cart-media a img {
  width: 100px;
  border-radius: 8px;
}

.cart-media button {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}

.cart-media button i {
  width: 32px;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  color: red !important;
  background: #fff;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
/* .deleteIcon {
  color: red;
} */
.cart-media button i:hover {
  color: #000;
  background: var(--red);
}

.cart-info-group {
  width: 100%;
}

.cart-info {
  margin-bottom: 13px;
}

.cart-info h6 {
  font-weight: 400;
  text-transform: capitalize;
}

.cart-info h6 a {
  color: var(--heading);
}

.cart-info p {
  font-size: 14px;
  color: #49b857;
}

.cart-action-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart-action-group .product-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cart-action-group .product-action button i {
  width: 30px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  border-radius: 5px;
}

.cart-action-group .product-action input {
  width: 45px;
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  color: var(--primary);
  background: var(--chalk);
}

.cart-action-group h6 {
  font-weight: 500;
  color: var(--primary);
}

.cart-footer {
  padding: 20px 15px 0px;
  text-align: center;
  -webkit-box-shadow: 0px -3px 7px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px -3px 7px 0px rgba(0, 0, 0, 0.08);
}

.coupon-btn {
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--primary);
  text-shadow: var(--primary-tshadow);
}

.coupon-btn:hover {
  text-decoration: underline;
}

.coupon-form {
  padding: 3px;
  border-radius: 8px;
  margin-bottom: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid var(--border);
  -webkit-box-shadow: 0px 7px 13px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 13px 0px rgba(0, 0, 0, 0.1);
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  display: none;
}

.coupon-form:focus-within {
  border-color: var(--primary);
}

.coupon-form input {
  width: 100%;
  height: 38px;
  padding: 0px 15px;
}

.coupon-form button span {
  width: 100px;
  height: 38px;
  font-size: 14px;
  line-height: 38px;
  border-radius: 8px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
  background: var(--primary);
}

.cart-checkout-btn {
  padding: 10px 0px;
  border-radius: 8px;
  background: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.newsletter {
  display: flex;
  gap: 10px;
  align-items: center;
}
.customiseBtn {
  display: inline-block;
  color: #fff;
  background-color: #57cdbc;
  padding: 10px 19px;
  font-weight: 600;
  border-radius: 5px;
}
.subscribeRow {
  background-color: #1091b1;
  padding: 15px;
  background-color: gray; /* For browsers that do not support gradients */
  background-image: linear-gradient(#000000c7, #0792bd);
  box-shadow: 4px 4px 10px #00000085;
  border-radius: 15px;
}
.figureContent {
  height: 450px;
}
.mainDivCustomise {
  display: flex;
  justify-content: space-around;
  gap: 25px;
}
.customiseBtn:hover {
  color: #fff;
  background-color: red;
}
.mainDivContinueCart {
  border: 1px solid gray;
  padding: 10px;
  margin: 20px 0px;
  border-radius: 7px;
  width: 30%;
}
.totalTitle {
  color: #000;
  font-weight: 600;
}
.shopingDiv {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.cartTitle {
  border: 1px solid #57cdbc;
  padding: 10px 10px;
  background: #57cdbc;
  color: #fff;
  border-radius: 7px;
}
.cart-checkout-btn:hover {
  background: red;
}

.checkout-label {
  width: 100%;
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.3px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.checkout-price {
  padding: 0px 25px;
  letter-spacing: 0.3px;
  color: #fff;
  border-left: 1px solid #fff;
  display: flex;
  align-items: center;
}

.counting {
  padding: 4px;
  width: 28px !important;
  height: 28px !important;
  border-radius: 5px;
  background: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counterDiv {
  padding: 10px 0px;
}
.addCartTitle {
  display: flex;
  justify-content: space-between;
  gap: 189px;
  align-items: center;
}
.popup-modal .modal-body .cart-table .rate {
  display: block;
}
.popup-modal .modal-body .cart-table .common-btn img {
  max-width: 30px;
}
.popup-modal .modal-body .cart-table .close {
  display: inline-block;
  color: #363636;
  font-size: 20px;
}
.popup-modal .modal-body .cart-table .close:hover {
  color: #f05454;
}
.popup-modal .modal-body .total-amount {
  margin-bottom: 30px;
}
.popup-modal .modal-body .total-amount h3 {
  font-size: 20px;
  margin-bottom: 0;
  position: relative;
}
.popup-modal .modal-body .total-amount h3 span {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 600;
}
.popup-modal .modal-footer {
  padding: 0;
  border: 0;
  display: block;
}
.popup-modal .modal-footer .form-control {
  height: 55px;
  border: 1px solid #434e6e;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 10px;
  padding-left: 15px;
}

.popup-modal .modal-footer .form-control:focus {
  border: 1px solid #434e6e;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.popup-modal .modal-footer .common-btn {
  height: 55px;
  border-radius: 10px;
  display: block;
  width: 100%;
  margin-top: 15px;
}
.popup-modal.modal-right .modal-dialog {
  max-width: 585px;
  margin: 0 0 0 auto;
  -webkit-transform: translate(100%, 70px);
  transform: translate(100%, 70px);
}
.popup-modal.modal-right.show .modal-dialog {
  -webkit-transform: translate(17px, 70px);
  transform: translate(17px, 70px);
}
.number {
  margin: 0;
  background-color: #434e6e;
  text-align: center;
  padding: 10px 0;
  border-radius: 10px;
}
.number li {
  list-style-type: none;
  display: inline-block;
}
.number li .minus {
  border-right: 1px solid #fff;
}
.number li .minus,
.number li .plus {
  color: #fff;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  vertical-align: middle;
}
.number li input {
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border: 0;
  display: inline-block;
  vertical-align: middle;
  background-color: #434e6e;
  color: #fff;
  border-right: 1px solid #fff;
}
.number li input:focus {
  outline: 0;
}
.wishlist-modal .modal-body .cart-table .table {
  margin-bottom: 0;
}
.wishlist-modal.modal-right .modal-dialog {
  -webkit-transform: translate(100%, 145px);
  transform: translate(100%, 145px);
}
.wishlist-modal.modal-right.show .modal-dialog {
  -webkit-transform: translate(17px, 145px);
  transform: translate(17px, 145px);
}
.header-area.two {
  background-color: gray;
}
.nav-top-area.two {
  background-color: #f5f5f5;
}
.nav-top-area.two .middle .form-group .form-control:focus {
  border: 1px solid gray;
}
.nav-top-area.two .middle .form-group .inner .nice-select {
  background-color: gray;
}
.nav-top-area.two .right ul li .join {
  background-color: gray;
  border: 1px solid gray;
}
.nav-top-area.two .right ul li .join:hover {
  background-color: #fff;
}
.main-nav.two {
  background-color: #fff;
  padding: 10px 0px;
}
.side-nav.two h4 a {
  color: gray;
  border-bottom: 1px solid gray;
}
.dashTitle {
  font-family: Garamond, serif;
  color: #000;
}
.side-nav.two h4 a:hover {
  color: #f05454;
  border-bottom: 1px solid #f05454;
}

.dropbtn {
  color: #000;
  padding: 8px;
  font-size: 16px;
  border: none;
  background-color: #fff;
}

.drobdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  background: #ffff;
  min-width: 200px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}
.nav-link {
  cursor: pointer;
}
.dropdown-content a {
  display: block;
  color: #000;
  text-decoration: none;
  font-size: 16px;
}

.dropdown-content a:hover {
  background: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  font-weight: 600;
  font-size: 16px;
  text-transform: unset;
  color: #363636;
  /* margin-left: 12px;
  margin-right: 12px; */
  position: relative;
  transition: 0.5s;
  border-bottom: 1px solid;
  width: auto;
}
.sale-area {
  /* margin-top: 100px; */
  overflow: hidden;
  background-color: white;
  border-bottom: 1px solid #fff;
}
.sale-area .row {
  padding-top: 30px;
}
.sale-area .container-fluid {
  max-width: 1720px;
}
.sale-bg-one {
  /* background-image: url(file:///C:/Users/rushil/Desktop/admin/newProject/templates.hibootstrap.com/ecop/default/assets/images/sale-bg1.png); */
  background-color: gray;
}
.sale-bg-two {
  /* background-image: url(file:///C:/Users/rushil/Desktop/admin/newProject/templates.hibootstrap.com/ecop/default/assets/images/sale-bg2.png); */
  /* background-color: #272e61; */
}
.sale-bg-three {
  /* background-image: url(file:///C:/Users/rushil/Desktop/admin/newProject/templates.hibootstrap.com/ecop/default/assets/images/sale-bg3.png); */
  background-color: #000000b0;
}
.sale-item {
  margin-bottom: 30px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px;
  padding: 40px 10px 40px 40px;
  position: relative;
  /* margin-right: 20px;
  margin-left: 33px; */
}
.sale-item img {
  /* bottom: 0px;
  width: 58%;
  position: absolute;
  right: 0;
  height: auto;
  z-index: 0; */
  bottom: 0;
  max-width: 172px;
  position: absolute;
  right: -16px;
}
.inner {
  z-index: 99999;
  position: relative;
}
.sale-item .inner h3 {
  font-size: 25px;
  color: #fff;
  margin-bottom: 8px;
  font-weight: 400;
  margin-top: -5px;
}
.sale-item .inner h3 span {
  font-weight: 700;
  display: inline-block;
}
.sale-item .inner h3 .percent {
  -webkit-animation: sale-bean-ani 0.25s infinite alternate;
  animation: sale-bean-ani 0.25s infinite alternate;
}
.sale-item .inner p {
  margin-bottom: 30px;
  color: #fff;
  font-weight: 600;
}
.sale-item .inner a {
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  display: inline-block;
  font-weight: 600;
  padding: 12px 25px;
  z-index: 2222222;
}
.sale-item .inner a:hover {
  color: #fff;
  background-color: #000;
}
.termsAndConditionLink:hover {
  color: red;
}
@-webkit-keyframes sale-bean-ani {
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes sale-bean-ani {
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.products-area.two {
  padding: 30px 0px;
}
/* .react-multi-carousel-track {
  gap: 15px;
} */
.support-area {
  background-color: #fff;
}
.react-multiple-carousel__arrow {
  /* top: 43px; */
  /* right: calc(0% + 1px); */
  z-index: 0;
}
.categoriesIcon {
  font-size: 50px;
  font-weight: 400;
}
.products-area.two .products-thumb {
  border: 1px solid #8080805e;
  margin-bottom: 30px;
  box-shadow: 1px 1px 8px 1px #b5b5b5;
}
.products-area.two .products-thumb:hover {
  background-color: #57cdbc;
}
.products-area.two .products-thumb a {
  display: block;
  color: #dbb226;
}
.products-area.two .products-thumb img {
  max-width: 40px;
}
.products-area.two .products-thumb i {
  font-weight: 400;
  font-size: 50px;
}
.products-area.two .owl-theme .owl-nav {
  margin-top: 0;
}
.products-area.two .owl-prev {
  position: absolute;
  left: -70px;
  top: 30%;
  width: 45px;
  height: 45px;
  line-height: 45px !important;
  border-radius: 10px !important;
  color: gray !important;
  background-color: #fff !important;
  font-size: 25px !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.products-area.two .owl-prev:hover {
  color: #fff !important;
  background-color: gray !important;
}
.products-area.two .owl-next {
  position: absolute;
  right: -70px;
  top: 30%;
  width: 45px;
  height: 45px;
  line-height: 45px !important;
  border-radius: 10px !important;
  color: gray !important;
  background-color: #fff !important;
  font-size: 25px !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.products-area.two .owl-next:hover {
  color: #fff !important;
  background-color: gray !important;
}
.products-area.two .products-item .bottom:hover i {
  color: #fff;
  background-color: gray;
}
.products-area.two .products-item .bottom a {
  color: gray;
}
.products-area.two .products-item .bottom a:hover {
  color: #f05454;
}
.products-area.two .products-item .bottom i {
  color: #000;
}
.buy-area.two .buy-shape img:nth-child(1) {
  max-width: 240px;
}
.buy-area.two .buy-shape img:nth-child(2) {
  left: 0;
  top: 0;
  max-width: 165px;
  -webkit-animation: buy-ani-two 8s infinite linear;
  animation: buy-ani-two 8s infinite linear;
}
.buy-area.two .buy-content {
  max-width: 100%;
  /* padding-left: 54px; */
}
.buy-area.two .buy-content h2 {
  color: #df5c5c;
}
.buy-area.two .buy-img {
  text-align: center;
}
.buy-area.two .buy-img img {
  -webkit-animation: buy-ani-three 6s infinite linear;
  animation: buy-ani-three 6s infinite linear;
}
@-webkit-keyframes buy-ani-three {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  50% {
    -webkit-transform: rotateY(30deg);
    transform: rotateY(30deg);
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}
@keyframes buy-ani-three {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  50% {
    -webkit-transform: rotateY(30deg);
    transform: rotateY(30deg);
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}
@-webkit-keyframes buy-ani-two {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(-50px, 0);
    transform: translate(-50px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes buy-ani-two {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(-50px, 0);
    transform: translate(-50px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.deal-area {
  background-color: #fff;
}
.deal-area .deal-black {
  background-color: #363636;
  text-align: center;
  padding: 25px 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}
.deal-area .deal-black img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.deal-area .deal-black h3 {
  font-size: 25px;
  margin-bottom: 5px;
  color: #fff;
  margin-top: -4px;
}
.deal-area .deal-black a {
  display: inline-block;
  color: #fff;
  border-bottom: 1px solid #fff;
}
.deal-area .deal-black a:hover {
  color: red;
  border-bottom: 1px solid red;
}
.deal-item {
  margin-bottom: 30px;
  /* background-color: red; */
  border-radius: 10px;
  position: relative;
  padding: 50px 20px 50px 50px;
}
.deal-item .percent {
  display: inline-block;
  color: red;
  background-color: #fff;
  border-radius: 0 30px 30px 0;
  position: absolute;
  top: 15px;
  left: 0;
  display: inline-block;
  padding: 5px 15px;
  font-weight: 600;
}
.deal-item .deal-btn {
  display: inline-block;
  color: #363636;
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 5px 0 5px 0;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 20px;
  z-index: 1;
}
.deal-item .deal-btn:hover {
  color: #fff;
  background-color: #000;
}
.deal-item .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}
.deal-item .inner .left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
  text-align: center;
}
.deal-item .inner .left img {
  -webkit-animation: deal-ani-one 5s infinite linear;
  animation: deal-ani-one 5s infinite linear;
}
.deal-item .inner .right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  max-width: 55%;
}
.deal-item .inner .right .reviews {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
.deal-item .inner .right .reviews li {
  list-style-type: none;
  display: inline-block;
}
.deal-item .inner .right .reviews li i {
  color: #ddd;
  font-size: 18px;
}
.deal-item .inner .right .reviews li i.checked {
  color: #f8d137;
}
.deal-item .inner .right .reviews li span {
  display: inline-block;
  color: #fff;
}
.deal-item .inner .right h3 {
  font-size: 25px;
  color: #fff;
  margin-bottom: 10px;
  line-height: 1.3;
}
.deal-item .inner .right .price {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
.deal-item .inner .right .price li {
  list-style-type: none;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-right: 15px;
}
.deal-item .inner .right .price li:nth-child(2) {
  font-weight: 400 !important;
  color: white !important;
  font-size: 14px !important;
}
.deal-item .inner .right .features {
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}
.deal-item .inner .right .features li {
  list-style-type: none;
  display: block;
  color: #fff;
  margin-bottom: 2px;
}
.deal-item .inner .right .features li:first-child {
  margin-bottom: 5px;
}
.deal-item .inner .right .features li:last-child {
  margin-bottom: 0;
}
.deal-item .inner .right .features li span {
  font-weight: 600;
}
.deal-item .inner .right .timer {
  margin: 0;
  padding: 0;
}
.deal-item .inner .right .timer li {
  list-style-type: none;
  display: inline-block;
  margin-right: 15px;
}
.deal-item .inner .right .timer li .timer-inner {
  text-align: center;
}
.deal-item .inner .right .timer li .timer-inner span {
  font-weight: 700;
  font-size: 18px;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  border-radius: 5px;
}
.deal-item .inner .right .timer li .timer-inner p {
  margin-bottom: 0;
  color: #fff;
}
.deal-item .inner .right h4 {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}
.deal-item .inner .right h4 span {
  font-weight: 700;
  font-size: 18px;
}
.deal-item .inner .right .cart-wishlist {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 35%;
  right: 0;
}
.deal-item .inner .right .cart-wishlist li {
  list-style-type: none;
  display: block;
  margin-bottom: 10px;
}
.deal-item .inner .right .cart-wishlist li a {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 50%;
  font-size: 20px;
}

.deal-item .inner .right .cart-wishlist li a i {
  line-height: 40px;
}
.deal-item .inner .right .cart-wishlist li a:hover {
  color: #fff;
  background-color: #000;
}
.deal-item.two {
  /* background-color: gray; */
}
@-webkit-keyframes deal-ani-one {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  50% {
    -webkit-transform: rotateY(35deg);
    transform: rotateY(35deg);
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}
@keyframes deal-ani-one {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  50% {
    -webkit-transform: rotateY(35deg);
    transform: rotateY(35deg);
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}
.testimonials-area.two .owl-prev {
  color: gray !important;
}
.testimonials-area.two .owl-prev:hover {
  color: #fff !important;
  background-color: gray !important;
}
.testimonials-area.two .owl-next {
  color: gray !important;
}
.testimonials-area.two .owl-next:hover {
  color: #fff !important;
  background-color: gray !important;
}
.testimonials-area.two .owl-item.center .testimonials-item {
  background-color: gray;
}
.testimonials-area.two .testimonials-item:hover {
  background-color: #81dacd;
}
.testimonials-area.two .testimonials-item:hover i {
  color: #fff;
}
.testimonials-area.two .testimonials-item i {
  color: #57cdbc;
}
.support-item.two {
  background-color: #df1d1d;
}
.support-item.two:hover i {
  color: #df1d1d;
  background-color: #fff;
  border: 10px solid #df1d1d;
}
.support-item.two i {
  background-color: #df1d1d;
}
.support-item.three {
  background-color: #0079ff;
}
.support-item.three:hover i {
  color: #0079ff;
  background-color: #fff;
  border: 10px solid #0079ff;
}
.support-item.three i {
  background-color: #0079ff;
}
.support-item.four {
  background-color: #3fc5f0;
}
.support-item.four:hover i {
  color: #3fc5f0;
  background-color: #fff;
  border: 10px solid #3fc5f0;
}
.support-item.four i {
  background-color: #3fc5f0;
}
.blog-area.two .blog-item:hover .top span {
  background-color: gray;
}
.blog-area.two .blog-item .bottom h3 a:hover {
  color: gray;
}
.blog-area.two .blog-item .bottom .blog-btn:hover {
  color: gray;
}
.footer-area.two .footer-item h3 {
  color: #d73333;
}
.footer-area.two .social-links ul li a {
  background-color: gray;
}
.footer-item ul li {
  list-style-type: none !important;
}
.footer-item ul {
  padding-left: 0px;
}
.footer-area.two .social-links ul li a .iconLinkFacbook {
  background-color: rgb(59, 89, 152) !important;
}
.iconLinkFacbook {
  background-color: rgb(59, 89, 152) !important;
}
.footer-area.two .social-links ul li a .shareIconTwiter {
  background-color: #bc2a8d !important;
}
.shareIconTwiter {
  background-color: #00aced !important;
}
.shareIconYoutube {
  background-color: #c40316 !important;
}
.sharIconInstagram {
  background-color: #bc2a8d !important;
}
.shareIconPinterest {
  background-color: #cb2028 !important;
}
.copyright-area.two {
  background-color: #d73333;
}
.cart-popup-btn.two .one {
  background-color: gray;
}
.cart-popup-btn.two .two {
  color: gray;
}
.header-area.three {
  background-color: #ff5f59;
}
.nav-top-area.three .middle .form-group .form-control:focus {
  border: 1px solid #ff5f59;
}
.nav-top-area.three .middle .form-group .inner .nice-select {
  background-color: #ff5f59;
}
.nav-top-area.three .right ul li .join {
  background-color: #ff5f59;
  border: 1px solid #ff5f59;
}
.nav-top-area.three .right ul li .join:hover {
  background-color: #fff;
}
.side-nav.three h4 a {
  color: #ff5f59;
  border-bottom: 1px solid #ff5f59;
}
.side-nav.three h4 a:hover {
  color: #f05454;
  border-bottom: 1px solid #f05454;
}
.video-area {
  background-color: #f5f5f5;
  margin-top: 100px;
  padding-top: 30px;
}
.video-area .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  left: 0;
  right: 140px;
  bottom: 40px;
}
.video-area .owl-prev {
  width: 45px;
  height: 45px;
  line-height: 45px !important;
  border-radius: 10px !important;
  color: #ff5f59 !important;
  background-color: #fff !important;
  font-size: 25px !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.video-area .owl-prev:hover {
  color: #fff !important;
  background-color: #ff5f59 !important;
}
.video-area .owl-next {
  width: 45px;
  height: 45px;
  line-height: 45px !important;
  border-radius: 10px !important;
  color: #ff5f59 !important;
  background-color: #fff !important;
  font-size: 25px !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.video-area .owl-next:hover {
  color: #fff !important;
  background-color: #ff5f59 !important;
}
.video-area .video-left {
  margin-bottom: 30px;
}
.video-area .video-left .form-group {
  margin-bottom: 15px;
}
.video-area .video-left .form-group:last-child {
  margin-bottom: 0;
}
.video-area .video-left .form-group .btn-video-left {
  width: 100%;
  height: 60px;
  font-size: 16px;
  color: #fff;
  background-color: #ff5f59;
  border-radius: 10px;
  padding: 0;
}
.video-area .video-left .form-group .btn-video-left .icon {
  display: inline-block;
  font-size: 22px;
  margin-right: 3px;
  position: relative;
  top: 2px;
}
.video-area .video-left .form-group .btn-video-left .icon-two {
  display: inline-block;
  font-size: 22px;
  margin-left: 3px;
  position: relative;
  top: 4px;
}
.video-area .video-left .form-group .dropdown-menu {
  border-radius: 10px;
  border: 0;
  width: 100%;
  padding: 0;
}
.video-area .video-left .form-group .dropdown-menu li {
  border-bottom: 1px solid #f4f4f4;
}
.video-area .video-left .form-group .dropdown-menu li:last-child {
  border-bottom: 0;
}
.video-area .video-left .form-group .dropdown-menu li a {
  padding: 10px 15px;
}
.video-area .video-left .form-group .dropdown-menu li a:hover {
  color: #fff;
  background-color: #ff5f59;
}
.video-area .video-left .dropdown-toggle::after {
  display: none;
}
.video-area .products-item .top .inner h3 {
  font-size: 20px;
}

.video-item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 300px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 30px;
}
.video-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.4;
  border-radius: 10px;
}
.video-item .inner {
  text-align: center;
  max-width: 425px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.video-item .inner .video-wrap {
  margin-bottom: 20px;
}
.video-item .inner h3 {
  font-size: 25px;
  margin-bottom: 0;
  color: #fff;
  line-height: 1.3;
}
.video-item .inner-two {
  padding-left: 25px;
  position: relative;
  max-width: 420px;
}
.video-item .inner-two h2 {
  font-size: 35px;
  color: #fff;
  margin-bottom: 15px;
  line-height: 1.3;
}
.video-wrap {
  z-index: 2;
}
.video-wrap .js-modal-btn {
  z-index: 5;
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  font-size: 55px;
  text-align: center;
  color: #ff5f59;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.video-wrap .js-modal-btn i {
  line-height: 75px;
}
.video-wrap .js-modal-btn:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}
.video-wrap .js-modal-btn:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.video-wrap .js-modal-btn:hover {
  color: #fff;
}
.video-wrap .js-modal-btn:hover:before {
  background-color: #ff5f59;
}
.video-wrap .js-modal-btn:hover:after {
  background-color: #ff5f59;
}
@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.products-area.three .products-item .top .inner h3 {
  font-size: 20px;
}
.products-area.three .products-item .bottom:hover i {
  color: #fff;
  background-color: #ff5f59;
}
.products-area.three .products-item .bottom a {
  color: #0260c1;
}
.products-area.three .products-item .bottom a:hover {
  color: #f05454;
}
.products-area.three .products-item .bottom i {
  color: #ff5f59;
}
.buy-area.three .buy-content {
  max-width: 100%;
  padding-left: 0;
}
.buy-area.three .buy-content h2 {
  color: #ff5f59;
}
.buy-area.three .buy-content p {
  font-weight: 400;
  margin-bottom: 18px;
}
.buy-area.three .buy-content .common-btn.three {
  padding: 12px 25px;
}
.buy-area.three .buy-content .common-btn.three i {
  margin-right: 3px;
}
.buy-area.three .buy-content .buy-btn {
  margin-right: 10px;
}
.testimonials-wrap {
  position: relative;
}
.testimonials-wrap img {
  position: absolute;
  z-index: 2;
}
.testimonials-wrap img:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
}
.testimonials-area.three .owl-prev {
  color: #ff5f59 !important;
}
.testimonials-area.three .owl-prev:hover {
  color: #fff !important;
  background-color: #ff5f59 !important;
}
.testimonials-area.three .owl-next {
  color: #ff5f59 !important;
}
.testimonials-area.three .owl-next:hover {
  color: #fff !important;
  background-color: #ff5f59 !important;
}
.testimonials-area.three .testimonials-item {
  padding: 50px 180px 75px;
  background-color: #ff5f59;
  margin-top: 0;
}
.testimonials-area.three .testimonials-item i {
  margin-top: 0;
  color: #fff;
}
.testimonials-area.three .testimonials-item p {
  font-size: 26px;
  color: #fff;
}
.testimonials-area.three .testimonials-item h3 {
  color: #fff;
}
.testimonials-area.three .testimonials-item span {
  color: #fff;
}
.support-area.three {
  position: relative;
}
.support-area.three .support-shape img {
  position: absolute;
}
.support-area.three .support-shape img:nth-child(1) {
  top: 0;
  left: 0;
}
.support-area.three .support-shape img:nth-child(2) {
  bottom: 0;
  right: 0;
}
.support-item.five {
  background-color: #ff5f59;
}
.support-item.five:hover i {
  color: #ff5f59;
  background-color: #fff;
  border: 10px solid #ff5f59;
}
.support-item.five i {
  background-color: #ff5f59;
}
.blog-area.three .blog-item:hover .top span {
  background-color: #ff5f59;
}
.blog-area.three .blog-item .bottom h3 a:hover {
  color: #ff5f59;
}
.blog-area.three .blog-item .bottom .blog-btn:hover {
  color: #ff5f59;
}
.blog-area.three .blog-item-right .inner {
  margin-bottom: 30px;
}
.blog-area.three .blog-item-right .inner ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.blog-area.three .blog-item-right .inner ul li {
  list-style-type: none;
  display: inline-block;
}
.blog-area.three .blog-item-right .inner ul li:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.blog-area.three .blog-item-right .inner ul li:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
  margin-left: 15px;
}
.blog-area.three .blog-item-right .inner ul li span {
  display: block;
  color: #ff5f59;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.2;
  padding: 12px 15px;
}
.blog-area.three .blog-item-right .inner ul li h3 {
  font-size: 18px;
  margin-bottom: 5px;
  line-height: 1.4;
}
.blog-area.three .blog-item-right .inner ul li h3 a {
  color: #363636;
}
.blog-area.three .blog-item-right .inner ul li h3 a:hover {
  color: #ff5f59;
}
.blog-area.three .blog-item-right .inner ul li .blog-btn {
  color: #ff5f59;
  display: inline-block;
  font-weight: 600;
}
.blog-area.three .blog-item-right .inner ul li .blog-btn i {
  display: inline-block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 17px;
  position: relative;
  top: 3px;
}
.blog-area.three .blog-item-right .inner ul li .blog-btn:hover {
  color: #363636;
}
.footer-area.three .footer-item h3 {
  color: #ff5f59;
}
.footer-area.three .social-links ul li a {
  background-color: #ff5f59;
}
.copyright-area.three {
  background-color: #ff5f59;
}
.cart-popup-btn.three .one {
  background-color: #ff5f59;
}
.cart-popup-btn.three .two {
  color: #ff5f59;
}
.banner-area-two {
  background-color: #f5f5f5;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 130px;
  position: relative;
}
.banner-area-two:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 50px;
  top: -45px;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
}
.banner-area-two .banner-item {
  height: 600px;
  position: relative;
  background-color: #fff;
  border-radius: 30px;
}
.banner-area-two .banner-item .banner-content {
  max-width: 675px;
  position: relative;
  z-index: 2;
  margin-top: 0;
}
.banner-area-two .banner-item .banner-content h1 {
  font-size: 60px;
  margin-bottom: 15px;
}
.banner-area-two .banner-item .banner-content p {
  font-size: 18px;
  margin-bottom: 30px;
}
.banner-area-two .banner-item .banner-img img {
  position: absolute;
  z-index: 1;
}
.banner-area-two .banner-item .banner-img img:nth-child(1) {
  top: 0;
  right: 0;
  height: 100%;
  max-width: 615px;
  border-radius: 30px;
}
.banner-area-two .banner-item .banner-img img:nth-child(2) {
  bottom: 40px;
  left: 15%;
  max-width: 50px;
}
.banner-area-two .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
}
.banner-area-two .owl-prev {
  width: 45px;
  height: 45px;
  line-height: 45px !important;
  border-radius: 10px !important;
  color: #434e6e !important;
  background-color: #fff !important;
  font-size: 25px !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 0 20px 0 #e6e5e5;
  box-shadow: 0 0 20px 0 #e6e5e5;
}
.banner-area-two .owl-prev:hover {
  color: #fff !important;
  background-color: #434e6e !important;
}
.banner-area-two .owl-next {
  width: 45px;
  height: 45px;
  line-height: 45px !important;
  border-radius: 10px !important;
  color: #434e6e !important;
  background-color: #fff !important;
  font-size: 25px !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 0 20px 0 #e6e5e5;
  box-shadow: 0 0 20px 0 #e6e5e5;
}
.banner-area-two .owl-next:hover {
  color: #fff !important;
  background-color: #434e6e !important;
}
.categoryImg {
  border-radius: 40px;
  box-shadow: 4px 4px 9px gray;
  width: 100%;
  height: 700px;
  object-fit: cover;
}
.page-title-area {
  height: 300px;
  background-color: #fff;
  position: relative;
  /* background-image: url("./../assets/img/BGIMAGE.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.page-title-area .title-content {
  margin-top: 50px;
  position: relative;
  z-index: 1;
  color: #fff;
}
.page-title-area .title-content h2 {
  font-size: 50px;
  margin-bottom: 15px;
  color: #fff;
}
.page-title-area .title-content ul {
  margin: 0;
  padding: 0;
}
.page-title-area .title-content ul li {
  list-style-type: none;
  display: inline-block;
  font-weight: 600;
  margin-right: 20px;
  position: relative;
}
.page-title-area .title-content ul li:before {
  position: absolute;
  content: ">";
  top: -2px;
  right: -18px;
  color: #fff;
  font-size: 20px;
}
.page-title-area .title-content ul li:last-child:before {
  display: none;
}
.page-title-area .title-content ul li a {
  display: block;
  color: #fff;
}
.page-title-area .title-content ul li a:hover {
  color: #f05454;
}
.page-title-area .title-content ul li span {
  display: block;
}
.page-title-area .title-img img {
  position: absolute;
}
.page-title-area .title-img img:nth-child(1) {
  top: 0;
  right: 0;
  height: 100%;
}
.page-title-area .title-img img:nth-child(2) {
  left: 20%;
  bottom: 10px;
  -webkit-animation: page-title-ani-one 10s infinite linear;
  animation: page-title-ani-one 10s infinite linear;
}
.page-title-area .title-img img:nth-child(3) {
  left: 35%;
  top: 155px;
  -webkit-animation: page-title-ani-two 10s infinite linear;
  animation: page-title-ani-two 10s infinite linear;
}
.page-title-area .title-img img:nth-child(4) {
  bottom: 0;
  left: 0;
  max-width: 160px;
}
@-webkit-keyframes page-title-ani-one {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  30% {
    -webkit-transform: translate(25px, 25px);
    transform: translate(25px, 25px);
  }
  60% {
    -webkit-transform: translate(50px, -100px);
    transform: translate(50px, -100px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes page-title-ani-one {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  30% {
    -webkit-transform: translate(25px, 25px);
    transform: translate(25px, 25px);
  }
  60% {
    -webkit-transform: translate(50px, -100px);
    transform: translate(50px, -100px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes page-title-ani-two {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  30% {
    -webkit-transform: translate(-25px, -25px);
    transform: translate(-25px, -25px);
  }
  60% {
    -webkit-transform: translate(-50px, 100px);
    transform: translate(-50px, 100px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes page-title-ani-two {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  30% {
    -webkit-transform: translate(-25px, -25px);
    transform: translate(-25px, -25px);
  }
  60% {
    -webkit-transform: translate(-50px, 100px);
    transform: translate(-50px, 100px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.about-area {
  background-color: #f5f5f5;
}
.about-area .about-content {
  margin-bottom: 30px;
}
.about-area .about-content .top {
  margin-bottom: 25px;
}
.about-area .about-content .top h2 {
  font-size: 40px;
  margin-bottom: 10px;
}
.about-area .about-content .top p {
  margin-bottom: 4px;
}
.about-area .about-content .top p:last-child {
  margin-bottom: 0;
}
.about-area .about-content .middle ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.about-area .about-content .middle ul li {
  list-style-type: none;
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  padding: 25px 15px 25px 100px;
  margin-bottom: 25px;
  margin-right: 25px;
}
.about-area .about-content .middle ul li img {
  position: absolute;
  top: 20px;
  left: 25px;
}
.about-area .about-content .middle ul li h3 {
  font-size: 20px;
  color: #545454;
  margin-bottom: 0;
  line-height: 1.3;
}
.about-area .about-content .middle p {
  margin-bottom: 25px;
}
.about-area .about-img {
  margin-bottom: 30px;
}
.about-area .about-img img {
  border-radius: 15px;
}
.about-area .about-img img:nth-child(1) {
  /* padding-left: 55px; */
}
.about-area .about-img img:nth-child(2) {
  margin-top: -115px;
  max-width: 285px;
}
.about-area .about-img img:nth-child(3) {
  margin-top: -99px;
  margin-left: 217px;
  max-width: 315px;
}
.sliderBanner{
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.blog-details-area {
  background-color: #f5f5f5;
}
.blog-details-area .details-item .details-img {
  padding-bottom: 30px;
  margin-bottom: 25px;
  border-bottom: 1px solid #bebebe;
}
.blog-details-area .details-item .details-img img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}
.blog-details-area .details-item .details-img .details-date {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.blog-details-area .details-item .details-img .details-date li {
  list-style-type: none;
  display: inline-block;
  margin-right: 15px;
  font-size: 15px;
}
.blog-details-area .details-item .details-img .details-date li i {
  display: inline-block;
  font-size: 20px;
  margin-right: 7px;
  position: relative;
  top: 3px;
}
.blog-details-area .details-item .details-img .details-date li span {
  display: inline-block;
}
.blog-details-area .details-item .details-img .details-date li a {
  display: inline-block;
  color: #545454;
}
.blog-details-area .details-item .details-img .details-date li a:hover {
  color: #f05454;
}
.blog-details-area .details-item .details-img h2 {
  font-size: 27px;
  margin-bottom: 10px;
}
.blog-details-area .details-item .details-img p {
  margin-bottom: 5px;
}
.blog-details-area .details-item .details-img .details-list {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog-details-area .details-item .details-img .details-list li {
  list-style-type: none;
  display: block;
  margin-bottom: 10px;
}
.blog-details-area .details-item .details-img .details-list li:last-child {
  margin-bottom: 0;
}
.blog-details-area .details-item .details-img .details-list li span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #434e6e;
  border-radius: 5px;
  position: relative;
  margin-right: 5px;
  top: 2px;
}
.blog-details-area .details-item .details-img .details-list li span:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background-color: #fff;
}
.blog-details-area .details-item .details-img .details-social {
  margin: 0;
  padding: 0;
  margin-top: 20px;
}
.blog-details-area .details-item .details-img .details-social li {
  list-style-type: none;
  display: inline-block;
  margin-left: 5px;
}
.blog-details-area .details-item .details-img .details-social li span {
  display: inline-block;
  font-weight: 600;
}
.blog-details-area .details-item .details-img .details-social li a {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  background-color: #434e6e;
  font-size: 16px;
  text-align: center;
}
.blog-details-area .details-item .details-img .details-social li a i {
  line-height: 30px;
}
.blog-details-area .details-item .details-img .details-social li a:hover {
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}
.blog-details-area .details-item .details-arrow-page {
  margin-bottom: 30px;
  border-bottom: 1px solid #bebebe;
}
.blog-details-area .details-item .details-arrow-page .left {
  margin-bottom: 30px;
}
.blog-details-area .details-item .details-arrow-page .left .arrows {
  display: inline-block;
  color: #545454;
  border-bottom: 1px solid #545454;
  margin-bottom: 15px;
}
.blog-details-area .details-item .details-arrow-page .left .arrows i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 3px;
  margin-right: -3px;
}
.blog-details-area .details-item .details-arrow-page .left .arrows:hover {
  color: #f05454;
  border-bottom: 1px solid #f05454;
}
.blog-details-area .details-item .details-arrow-page .left ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.blog-details-area .details-item .details-arrow-page .left ul li {
  list-style-type: none;
  display: inline-block;
}
.blog-details-area .details-item .details-arrow-page .left ul li:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.blog-details-area .details-item .details-arrow-page .left ul li:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
  margin-left: 10px;
}
.blog-details-area .details-item .details-arrow-page .left ul li img {
  border-radius: 10px;
  width: 100%;
}
.blog-details-area .details-item .details-arrow-page .left ul li h3 {
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 1.3;
}
.blog-details-area .details-item .details-arrow-page .left ul li h3 a {
  color: #363636;
}
.blog-details-area .details-item .details-arrow-page .left ul li h3 a:hover {
  color: #f05454;
}
.blog-details-area .details-item .details-arrow-page .left ul li i {
  display: inline-block;
  font-size: 17px;
  position: relative;
  top: 1px;
}
.blog-details-area .details-item .details-arrow-page .left ul li span {
  display: inline-block;
}
.blog-details-area .details-item .details-arrow-page .right {
  margin-bottom: 30px;
  text-align: right;
}
.blog-details-area .details-item .details-arrow-page .right .arrows {
  display: inline-block;
  color: #545454;
  border-bottom: 1px solid #545454;
  margin-bottom: 15px;
}
.blog-details-area .details-item .details-arrow-page .right .arrows i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 3px;
  margin-right: -3px;
}
.blog-details-area .details-item .details-arrow-page .right .arrows:hover {
  color: #f05454;
  border-bottom: 1px solid #f05454;
}
.blog-details-area .details-item .details-arrow-page .right ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.blog-details-area .details-item .details-arrow-page .right ul li {
  list-style-type: none;
  display: inline-block;
}
.blog-details-area .details-item .details-arrow-page .right ul li:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 77%;
  flex: 0 0 77%;
  max-width: 77%;
  margin-right: 10px;
}
.blog-details-area .details-item .details-arrow-page .right ul li:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.blog-details-area .details-item .details-arrow-page .right ul li img {
  border-radius: 10px;
  width: 100%;
}
.blog-details-area .details-item .details-arrow-page .right ul li h3 {
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 1.3;
}
.blog-details-area .details-item .details-arrow-page .right ul li h3 a {
  color: #363636;
}
.blog-details-area .details-item .details-arrow-page .right ul li h3 a:hover {
  color: #f05454;
}
.blog-details-area .details-item .details-arrow-page .right ul li i {
  display: inline-block;
  font-size: 17px;
  position: relative;
  top: 1px;
}
.blog-details-area .details-item .details-arrow-page .right ul li span {
  display: inline-block;
}
.blog-details-area .details-item .details-comments {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #bebebe;
}
.blog-details-area .details-item .details-comments h3 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #363636;
}
.blog-details-area .details-item .details-comments h3 span {
  color: #545454;
}
.blog-details-area .details-item .details-comments ul {
  margin: 0;
  padding: 0;
}
.blog-details-area .details-item .details-comments ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px 20px 120px;
  position: relative;
}
.blog-details-area .details-item .details-comments ul li:last-child {
  margin-bottom: 0;
}
.blog-details-area .details-item .details-comments ul li img {
  position: absolute;
  border-radius: 10px;
  top: 20px;
  left: 20px;
  max-width: 85px;
}
.blog-details-area .details-item .details-comments ul li h4 {
  font-size: 16px;
  color: #363636;
  margin-bottom: 5px;
}
.blog-details-area .details-item .details-comments ul li span {
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
}
.blog-details-area .details-item .details-comments ul li p {
  margin-bottom: 0;
}
.sizeChartContainer {
  padding: 40px 0px;
}
.rowSemiCustome {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}

.blog-details-area .details-item .details-comments ul li a {
  display: inline-block;
  color: #fff;
  background-color: #000;
  border-radius: 5px;
  padding: 4px 15px;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 15px;
}
.footSIzeDiv {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #000;
}
.accordion-button::after {
  background-color: #fff;
}
.blog-details-area .details-item .details-comments ul li a:hover {
  color: #fff;
  background-color: #363636;
}
.blog-details-area .details-item .details-form h3 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #363636;
}
.blog-details-area .details-item .details-form .form-group {
  margin-bottom: 15px;
}
.blog-details-area .details-item .details-form .form-group .form-control {
  border-radius: 5px;
  height: 50px;
  border: 0;
  width: 100%;
}
.blog-details-area .details-item .details-form .form-group .form-control:focus {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.blog-details-area .details-item .details-form .form-group textarea {
  padding-top: 13px;
  height: auto !important;
}
.blog-details-area .details-item .details-form .common-btn {
  width: 100%;
  display: block;
}
.widget-area .widget-item {
  margin-bottom: 30px;
}
.widget-area .widget-item h3 {
  font-size: 20px;
  color: #363636;
  margin-bottom: 15px;
}
.widget-area .widget-item:last-child {
  margin-bottom: 0;
}
.widget-area form {
  background-color: #434e6e;
  border-radius: 5px;
  position: relative;
  padding: 15px;
}
.widget-area form .form-control {
  height: 50px;
  border-radius: 30px;
  border: 0;
}
.widget-area form .form-control:focus {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.widget-area form .btn {
  padding: 0;
  position: absolute;
  top: 22px;
  right: 25px;
  color: #545454;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 25px;
}
.widget-area form .btn:hover {
  color: #f05454;
}
.widget-area .categories ul {
  margin: 0;
  padding: 0;
}
.widget-area .categories ul li {
  list-style-type: none;
  display: block;
  position: relative;
  background-color: #fff;
  margin-bottom: 15px;
  border-radius: 5px;
}
.widget-area .categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .categories ul li a {
  display: block;
  position: relative;
  color: #545454;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid transparent;
}
.widget-area .categories ul li a:hover {
  color: #f05454;
  border: 1px solid #f05454;
}
.widget-area .categories ul li a span {
  position: absolute;
  top: 10px;
  right: 15px;
}
.widget-area .articles .inner {
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 15px;
}
.widget-area .articles .inner:last-child {
  margin-bottom: 0;
}
.widget-area .articles .inner ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.widget-area .articles .inner ul li {
  list-style-type: none;
  display: block;
}
.widget-area .articles .inner ul li:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 23%;
  flex: 0 0 23%;
  max-width: 23%;
}
.widget-area .articles .inner ul li:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
  margin-left: 10px;
}
.widget-area .articles .inner ul li img {
  border-radius: 5px;
  width: 100%;
}
.widget-area .articles .inner ul li a {
  display: block;
  color: #363636;
  font-weight: 700;
  margin-bottom: 5px;
  font-family: poppins, sans-serif;
}
.widget-area .articles .inner ul li a:hover {
  color: #f05454;
}
.widget-area .articles .inner ul li i {
  display: inline-block;
  font-size: 16px;
  position: relative;
  top: 1px;
  margin-right: 3px;
}
.widget-area .articles .inner ul li span {
  display: inline-block;
  font-size: 14px;
}
.widget-area .tags h3 {
  margin-bottom: 7px;
}
.widget-area .tags ul {
  margin: 0;
  padding: 0;
}
.widget-area .tags ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 5px;
  margin-top: 8px;
}
.widget-area .tags ul li a {
  display: block;
  color: #545454;
  background-color: #fff;
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 15px;
  padding: 4px 15px;
}
.widget-area .tags ul li a:hover {
  color: #fff;
  background-color: #363636;
}
.checkout-area {
  background-color: #f5f5f5;
}
.checkout-area .section-title h2 {
  font-size: 30px;
}
.checkout-area .checkout-billing {
  margin-bottom: 30px;
}
.checkout-area .checkout-billing .form-group {
  margin-bottom: 15px;
}
.checkout-area .checkout-billing .form-group .form-control {
  height: 50px;
  border-radius: 5px;
  border: 0;
  padding-left: 15px;
}
.checkout-area .checkout-billing .form-group .form-control:focus {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.checkout-area .checkout-billing .form-group textarea {
  height: auto !important;
  padding-top: 13px;
}
.checkout-area .checkout-billing .form-group .nice-select {
  height: 50px;
  border-radius: 5px;
  border: 0;
  margin-bottom: 15px;
  line-height: 50px;
  color: #545454;
  width: 100%;
  display: block;
  font-size: 16px;
}
.checkout-area .checkout-billing .form-group .nice-select .list {
  width: 100%;
  display: block;
}
.checkout-area .checkout-billing .form-group .form-check {
  margin-bottom: 15px;
  padding-left: 26px;
}
.checkout-area .checkout-billing .form-group .form-check .form-check-label {
  font-size: 15px;
  color: #545454;
}
.checkout-area .checkout-billing .form-group .form-check .form-check-input {
  margin-left: -26px;
  width: 18px;
  height: 18px;
  border-radius: 0;
  margin-top: 2px;
  border: 1px solid transparent;
}
.checkout-area
  .checkout-billing
  .form-group
  .form-check
  .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #363636;
}
.checkout-area
  .checkout-billing
  .form-group
  .form-check
  .form-check-input:checked {
  background-color: #363636;
}
.checkout-area .checkout-billing .text-center .common-btn {
  display: block;
  width: 100%;
}
.checkout-area .checkout-billing ::-webkit-input-placeholder {
  color: #545454;
}
.checkout-area .checkout-billing :-ms-input-placeholder {
  color: #545454;
}
.checkout-area .checkout-billing ::-ms-input-placeholder {
  color: #545454;
}
.checkout-area .checkout-billing ::placeholder {
  color: #545454;
}
.checkout-area .checkout-order {
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px 0 #ddd;
  box-shadow: 0 0 20px 0 #ddd;
}
.checkout-area .checkout-order h3 {
  font-size: 20px;
  margin-bottom: 30px;
}
.checkout-area .checkout-order ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f4f4f4;
}
.checkout-area .checkout-order ul li {
  list-style-type: none;
  display: inline-block;
}
.checkout-area .checkout-order ul li:nth-child(1) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.checkout-area .checkout-order ul li:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  max-width: 55%;
  margin-left: 5px;
}
.checkout-area .checkout-order ul li:nth-child(3) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  max-width: 15%;
  margin-left: 5px;
}
.checkout-area .checkout-order ul li h4 {
  margin-bottom: 0;
  font-size: 16px;
}
.checkout-area .checkout-order ul li span {
  display: block;
}
.checkout-area .checkout-order .inner h3 {
  font-size: 16px;
  color: #545454;
  font-weight: 400;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f4f4f4;
}
.checkout-area .checkout-order .inner h3 span {
  position: absolute;
  top: 0;
  right: 0;
}
.checkout-area .checkout-order .inner h4 {
  font-size: 18px;
  margin-bottom: 0;
  position: relative;
}
.checkout-area .checkout-order .inner h4 span {
  position: absolute;
  top: 0;
  right: 0;
}
.checkout-area .checkout-method {
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px 0 #ddd;
  box-shadow: 0 0 20px 0 #ddd;
}
.checkout-area .checkout-method h3 {
  font-size: 20px;
  margin-bottom: 30px;
}
.checkout-area .checkout-method .form-check {
  padding-left: 32px;
  margin-bottom: 15px;
}
.checkout-area .checkout-method .form-check:last-child {
  margin-bottom: 0;
}
.checkout-area .checkout-method .form-check p {
  margin-bottom: 0;
  margin-top: 10px;
}
.checkout-area .checkout-method .form-check .form-check-label {
  color: #363636;
  margin-bottom: 0;
  font-size: 18px;
}
.checkout-area .checkout-method .form-check .form-check-input {
  /* margin-left: -32px; */
  width: 20px;
  height: 20px;
  margin-top: 3px;
  border: 1px dashed #363636;
}
.checkout-area .checkout-method .form-check .form-check-input:focus {
  border: 1px solid #363636;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.checkout-area .checkout-method .form-check .form-check-input:checked {
  background-color: #363636;
}
.checkout-area .checkout-method .form-check.two .form-check-label {
  font-size: 15px;
  color: #545454;
}
.checkout-area .checkout-method .form-check.two .form-check-label a {
  display: inline-block;
  color: #545454;
  font-weight: 600;
}
.checkout-area .checkout-method .form-check.two .form-check-label a:hover {
  color: #f05454;
}
.checkout-area .checkout-method .form-check.two .form-check-input {
  margin-left: -30px;
  width: 18px;
  height: 18px;
  border-radius: 0;
  margin-top: 3px;
  border: 1px solid #363636;
}
.checkout-area .checkout-method .form-check.two .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #363636;
}
.checkout-area .checkout-method .form-check.two .form-check-input:checked {
  background-color: #363636;
}
.product-details-area {
  background-color: #f5f5f5;
}
.product-details-area #thumbs.owl-carousel.owl-drag .owl-item {
  margin-right: 0 !important;
  max-width: 165px;
  width: 100% !important;
}
.product-details-area .top .top-img {
  margin-bottom: 30px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
}
.product-details-area .top .top-img a {
  display: inline-block;
}
.product-details-area .top .top-content {
  margin-bottom: 30px;
}
.product-details-area .top .top-content h2 {
  font-size: 30px;
  margin-bottom: 15px;
}
.product-details-area .top .top-content .reviews {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}
.product-details-area .top .top-content .reviews li {
  list-style-type: none;
  display: inline-block;
}
.product-details-area .top .top-content .reviews li h3 {
  font-size: 25px;
  margin-bottom: 0;
}
.product-details-area .top .top-content .reviews li i {
  display: block;
  color: #ddd;
  font-size: 18px;
}
.product-details-area .top .top-content .reviews li i.checked {
  color: #ffc107;
}
.product-details-area .top .top-content .reviews li span {
  display: inline-block;
  font-size: 15px;
  margin-left: 10px;
}
.product-details-area .top .top-content .reviews li:last-child {
  display: block;
  margin-top: 10px;
}
.product-details-area .top .top-content p {
  margin-bottom: 15px;
}
.product-details-area .top .top-content .tag {
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
}
.product-details-area .top .top-content .tag li {
  list-style-type: none;
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
}
.product-details-area .top .top-content .tag li:last-child {
  margin-bottom: 0;
}
.product-details-area .top .top-content .tag li span {
  font-weight: 400;
}
.product-details-area .top .top-content .cart {
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}
.product-details-area .top .top-content .cart li {
  list-style-type: none;
  display: inline-block;
}
.product-details-area .top .top-content .cart li .number {
  overflow: hidden;
  width: 120px;
  padding: 8px 0;
}
.product-details-area .top .top-content .cart li .common-btn {
  margin-left: 15px;
  color: #434e6e;
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px 0 #dddddd59;
  box-shadow: 0 0 20px 0 #dddddd59;
}
.product-details-area .top .top-content .cart li .common-btn:hover {
  color: #fff;
  background-color: #434e6e;
}
.product-details-area .top .wishlist-btn {
  display: inline-block;
  color: #545454;
  font-weight: 600;
}
.product-details-area .top .wishlist-btn i {
  display: inline-block;
  margin-right: 3px;
  color: #f05454;
  position: relative;
  font-size: 20px;
  top: 3px;
}
.product-details-area .top .wishlist-btn:hover {
  color: #434e6e;
}
.product-details-area .bottom .nav-pills {
  display: block;
  text-align: center;
  margin-bottom: 15px;
}
.product-details-area .bottom .nav-pills li {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}
.product-details-area .bottom .nav-pills li a {
  padding: 10px 25px;
  color: #545454;
  background-color: #fff;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
}
.product-details-area .bottom .nav-pills li a.active {
  color: #fff;
  background-color: #434e6e;
}
.product-details-area .bottom .bottom-description p {
  margin-bottom: 5px;
}
.product-details-area .bottom .bottom-description ul {
  margin: 0;
  padding: 0;
  margin-top: 20px;
}
.product-details-area .bottom .bottom-description ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 10px;
}
.product-details-area .bottom .bottom-description ul li:last-child {
  margin-bottom: 0;
}
.product-details-area .bottom .bottom-description ul li span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #434e6e;
  border-radius: 5px;
  position: relative;
  margin-right: 5px;
  top: 2px;
}
.product-details-area .bottom .bottom-description ul li span:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background-color: #fff;
}
.product-details-area .bottom .bottom-comment {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #bebebe;
}
.product-details-area .bottom .bottom-comment h3 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #363636;
}
.product-details-area .bottom .bottom-comment h3 span {
  color: #545454;
}
.product-details-area .bottom .bottom-comment .comments {
  margin: 0;
  padding: 0;
}
.product-details-area .bottom .bottom-comment .comments li {
  list-style-type: none;
  display: block;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px 20px 120px;
  position: relative;
}
.product-details-area .bottom .bottom-comment .comments li:last-child {
  margin-bottom: 0;
}
.product-details-area .bottom .bottom-comment .comments li img {
  position: absolute;
  border-radius: 10px;
  top: 20px;
  left: 20px;
  max-width: 85px;
}
.product-details-area .bottom .bottom-comment .comments li h4 {
  font-size: 16px;
  color: #363636;
  margin-bottom: 5px;
}
.product-details-area .bottom .bottom-comment .comments li span {
  display: block;
  margin-bottom: 8px;
  font-size: 15px;
}
.product-details-area .bottom .bottom-comment .comments li p {
  margin-bottom: 0;
}
.product-details-area .bottom .bottom-comment .comments .reviews {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
}
.product-details-area .bottom .bottom-comment .comments .reviews li {
  list-style-type: none;
  display: inline-block;
  padding: 0;
  margin: 0;
}
.product-details-area .bottom .bottom-comment .comments .reviews li i {
  display: block;
  color: #ddd;
  font-size: 18px;
}
.product-details-area .bottom .bottom-comment .comments .reviews li i.checked {
  color: #ffc107;
}
.product-details-area .bottom .bottom-review h3 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #363636;
}
.product-details-area .bottom .bottom-review .form-group {
  margin-bottom: 15px;
}
.product-details-area .bottom .bottom-review .form-group .form-control {
  border-radius: 5px;
  height: 50px;
  border: 0;
}
.product-details-area .bottom .bottom-review .form-group .form-control:focus {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.product-details-area .bottom .bottom-review .form-group textarea {
  padding-top: 13px;
  height: auto !important;
}
.product-details-area .bottom .bottom-review .common-btn {
  width: 100%;
  display: block;
}
.order-tracking-area {
  background-color: #f5f5f5;
}
.order-tracking-area .order-img {
  margin-bottom: 30px;
  text-align: center;
}
.order-tracking-area .order-img img {
  -webkit-animation: order-ani-one 5s infinite linear;
  animation: order-ani-one 5s infinite linear;
}
.order-tracking-area .order-content {
  margin-bottom: 30px;
}
.order-tracking-area .order-content h2 {
  font-size: 25px;
  margin-bottom: 30px;
}
.order-tracking-area .order-content .form-group {
  margin-bottom: 15px;
}
.order-tracking-area .order-content .form-group .form-control {
  height: 50px;
  border: 0;
  border-radius: 5px;
}
.order-tracking-area .order-content .form-group .form-control:focus {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.order-tracking-area .order-content .common-btn {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.order-tracking-area .order-content .form-check .form-check-label {
  color: #545454;
}
.order-tracking-area .order-content .form-check .form-check-label a {
  display: inline-block;
  color: #545454;
  font-weight: 600;
}
.order-tracking-area .order-content .form-check .form-check-label a:hover {
  color: #f05454;
}
.order-tracking-area .order-content .form-check .form-check-input {
  margin-left: -25px;
  width: 18px;
  height: 18px;
  border-radius: 0;
  margin-top: 3px;
  border: 1px solid #363636;
}
.order-tracking-area .order-content .form-check .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #363636;
}
.order-tracking-area .order-content .form-check .form-check-input:checked {
  background-color: #363636;
}
@-webkit-keyframes order-ani-one {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes order-ani-one {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.common-faq-area {
  background-color: #f5f5f5;
}
.common-faq-area .faq-item .accordion {
  margin: 0;
  padding: 0;
}
.common-faq-area .faq-item .accordion li {
  position: relative;
  list-style-type: none;
  margin-bottom: 20px;
  display: block;
  -webkit-box-shadow: 0 0 20px 0 #dddddd75;
  box-shadow: 0 0 20px 0 #dddddd75;
}
.common-faq-area .faq-item .accordion li:last-child {
  margin-bottom: 0;
}
.common-faq-area .faq-item .accordion li .faq-head {
  color: #363636;
  font-size: 18px;
  width: 100%;
  display: block;
  cursor: pointer;
  font-weight: 600;
  padding: 18px 35px 18px 18px;
  margin-bottom: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #f9f9f9;
  border: 1px solid transparent;
  background-color: #fff;
}
.common-faq-area .faq-item .accordion li .faq-head:after {
  position: absolute;
  right: -25px;
  content: "+";
  top: 5px;
  color: #363636;
  font-size: 25px;
  width: 50px;
  height: 50px;
  line-height: 40px;
  border-radius: 50%;
  border: 5px solid #f5f5f5;
  text-align: center;
  background-color: #fff;
}
.common-faq-area .faq-item .accordion li .faq-head.active {
  color: #fff;
  background-color: #434e6e;
  border: 1px solid #434e6e;
}
.common-faq-area .faq-item .accordion li .faq-head.active:after {
  content: "-";
  font-size: 25px;
  color: #363636;
}
.common-faq-area .faq-item .accordion li .faq-content {
  display: none;
  background-color: #fff;
  padding: 12px 45px 15px 20px;
}
.common-faq-area .faq-item .accordion li .faq-content .inner-list {
  margin: 0;
  padding: 0;
}
.common-faq-area .faq-item .accordion li .faq-content .inner-list li {
  list-style-type: none;
  display: block;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  margin-bottom: 10px;
}
.common-faq-area
  .faq-item
  .accordion
  li
  .faq-content
  .inner-list
  li:last-child {
  margin-bottom: 0;
}
.common-faq-area .faq-item .accordion li .faq-content p {
  margin-bottom: 0;
}
.common-faq-area .faq-item .accordion li .faq-content a {
  display: inline-block;
  color: #434e6e;
  font-weight: 600;
}
.common-faq-area .faq-item .accordion li .faq-content a:hover {
  color: #f05454;
}
.contact-area {
  background-color: #f5f5f5;
}
.contact-area #contactForm {
  margin-bottom: 30px;
}
.contact-area #contactForm .form-group {
  margin-bottom: 15px;
}
.contact-area #contactForm .form-group .form-control {
  border-radius: 5px;
  height: 50px;
  border: 0;
  padding-left: 15px;
}
.contact-area #contactForm .form-group .form-control:focus {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.contact-area #contactForm .form-group textarea {
  height: auto !important;
  padding-top: 13px;
}
.contact-area #contactForm .form-group .form-check .form-check-label {
  color: #545454;
}
.contact-area #contactForm .form-group .form-check .form-check-label a {
  display: inline-block;
  color: #545454;
  font-weight: 600;
}
.contact-area #contactForm .form-group .form-check .form-check-label a:hover {
  color: #f05454;
}
.contact-area #contactForm .form-group .form-check .form-check-input {
  margin-left: -25px;
  width: 18px;
  height: 18px;
  border-radius: 0;
  margin-top: 3px;
  border: 1px solid #363636;
}
.contact-area #contactForm .form-group .form-check .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #363636;
}
.contact-area #contactForm .form-group .form-check .form-check-input:checked {
  background-color: #363636;
}
.contact-area #contactForm .common-btn {
  width: 100%;
  display: block;
}
.contact-area #contactForm .list-unstyled {
  color: #dc3545 !important;
  margin-top: 10px;
}
.contact-area #contactForm .text-danger {
  color: #dc3545 !important;
  margin-top: 10px;
}
.contact-area #contactForm .text-success {
  color: #28a745 !important;
  margin-top: 10px;
}
.contact-area .contact-info {
  margin-bottom: 30px;
}
.contact-area .contact-info h3 {
  font-size: 20px;
  color: #363636;
  margin-bottom: 25px;
}
.contact-area .contact-info .info {
  margin: 0;
  padding: 0;
}
.contact-area .contact-info .info li {
  list-style-type: none;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
}
.contact-area .contact-info .info li i {
  display: inline-block;
  font-size: 25px;
  position: absolute;
  top: 0;
  left: 0;
}
.globalTitle {
  background-color: black;
  color: #fff;
  padding: 10px;
}
.sizeChartContainerTitle {
  padding: 20px 0px;
}
.contact-area .contact-info .info li a {
  display: block;
  color: #545454;
  margin-bottom: 3px;
}
.contact-area .contact-info .info li a:last-child {
  margin-bottom: 0;
}
.contact-area .contact-info .info li a:hover {
  color: #f05454;
}
.contact-area .contact-info .social-links {
  text-align: left;
  margin-bottom: 0;
}
.map-area {
  background-color: #f5f5f5;
}
.map-area #map {
  width: 100%;
  height: 400px;
  display: block;
  border-radius: 10px;
}
.user-area {
  background-color: #f5f5f5;
}
.user-area .user-item {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  background-color: #fff;
  padding: 50px;
  border: 2px solid #2ec1ac;
}
.user-area .user-item h2 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: -4px;
}
.user-area .user-item .form-group {
  margin-bottom: 15px;
}
.user-area .user-item .form-group .form-control {
  height: 50px;
  border: 0;
  border-radius: 5px;
  padding-left: 15px;
  background-color: #f5f5f5;
  width: 100%;
}
.user-area .user-item .form-group .form-control:focus {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.user-area .user-item .form-group .form-check .form-check-label {
  color: #545454;
}
.user-area .user-item .form-group .form-check .form-check-label a {
  display: inline-block;
  color: #545454;
  font-weight: 600;
}
.user-area .user-item .form-group .form-check .form-check-label a:hover {
  color: #f05454;
}
.user-area .user-item .form-group .form-check .form-check-input {
  margin-left: -25px;
  width: 18px;
  height: 18px;
  border-radius: 0;
  margin-top: 3px;
  border: 1px solid #363636;
}
.user-area .user-item .form-group .form-check .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #363636;
}
.user-area .user-item .form-group .form-check .form-check-input:checked {
  background-color: #363636;
}
.user-area .user-item .common-btn {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}
.user-area .user-item h4 {
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 16px;
  position: relative;
  text-align: center;
}
.user-area .user-item h4:before {
  position: absolute;
  content: "";
  top: 9px;
  left: 0;
  width: 45%;
  height: 1px;
  background-color: #ddd;
}
.user-area .user-item h4:after {
  position: absolute;
  content: "";
  top: 9px;
  right: 0;
  width: 45%;
  height: 1px;
  background-color: #ddd;
}
.user-area .user-item ul {
  margin: 0;
  padding: 0;
  text-align: center;
  margin-bottom: 30px;
}
.user-area .user-item ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 15px;
}
.user-area .user-item ul li:last-child {
  margin-bottom: 0;
}
.user-area .user-item ul li a {
  display: block;
  color: #fff;
  background-color: #3b5998;
  border-radius: 5px;
  border: 1px solid #3b5998;
  padding: 10px 12px;
  font-weight: 600;
}
.user-area .user-item ul li a i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 3px;
}
.user-area .user-item ul li a:hover {
  color: #3b5998;
  background-color: #fff;
}
.user-area .user-item ul li:nth-child(2) a {
  background-color: #db3236;
  border: 1px solid #db3236;
}
.user-area .user-item ul li:nth-child(2) a:hover {
  color: #db3236;
  background-color: #fff;
}
.user-area .user-item h5 {
  margin-bottom: 0;
  font-weight: 400;
  color: #545454;
  font-size: 16px;
  text-align: center;
}
.user-area .user-item h5 a {
  color: #f05454;
  display: inline-block;
  font-weight: 600;
  border-bottom: 1px solid #f05454;
}
.user-area .user-item h5 a:hover {
  color: #545454;
  border-bottom: 1px solid #545454;
}
.error-area {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  text-align: center;
}
.error-area .error-content i {
  color: #434e6e;
  display: inline-block;
  font-size: 100px;
}
.error-area .error-content h1 {
  font-size: 130px;
  color: #434e6e;
  margin-bottom: 20px;
}
.error-area .error-content h2 {
  font-size: 25px;
  color: #434e6e;
  margin-bottom: 10px;
}
.error-area .error-content p {
  margin-bottom: 20px;
  font-weight: 500;
}
.return-policy-area {
  background-color: #f5f5f5;
}
.return-policy-area .return-policy-item h2 {
  font-size: 30px;
  margin-bottom: 30px;
}
.return-policy-area .return-policy-item .inner {
  margin-bottom: 30px;
}
.return-policy-area .return-policy-item .inner:last-child {
  margin-bottom: 0;
}
.return-policy-area .return-policy-item .inner h3 {
  font-size: 20px;
  margin-bottom: 5px;
}
.return-policy-area .return-policy-item .inner p {
  margin-bottom: 5px;
}
.return-policy-area .return-policy-item .inner p:last-child {
  margin-bottom: 0;
}
.rules-area {
  background-color: #f5f5f5;
}
.rules-area .rules-item .inner {
  margin-bottom: 30px;
}
.rules-area .rules-item .inner:last-child {
  margin-bottom: 0;
}
.rules-area .rules-item .inner h3 {
  font-size: 20px;
  margin-bottom: 10px;
}
.rules-area .rules-item .inner p {
  margin-bottom: 5px;
}
.rules-area .rules-item .inner p:last-child {
  margin-bottom: 0;
}
.rules-area .rules-item .inner ul {
  margin: 0;
  padding: 0;
  margin-top: 14px;
}
.rules-area .rules-item .inner ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 10px;
}
.rules-area .rules-item .inner ul li:last-child {
  margin-bottom: 0;
}
.rules-area .rules-item .inner ul li span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #434e6e;
  border-radius: 5px;
  position: relative;
  margin-right: 5px;
  top: 2px;
}
.rules-area .rules-item .inner ul li span:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background-color: #fff;
}
#modal-subscribe.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
  position: relative;
  top: 50%;
  left: 50%;
}
#modal-subscribe.show .modal-dialog {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#modal-subscribe .modal-dialog {
  max-width: 1000px;
  margin: 0;
}
#modal-subscribe .modal-dialog .modal-content {
  padding: 50px 50px 20px;
  border-radius: 10px;
  position: relative;
}
#modal-subscribe .modal-dialog .modal-content .subscribe-shape img {
  position: absolute;
}
#modal-subscribe
  .modal-dialog
  .modal-content
  .subscribe-shape
  img:nth-child(1) {
  top: 10px;
  left: 40%;
}
#modal-subscribe
  .modal-dialog
  .modal-content
  .subscribe-shape
  img:nth-child(2) {
  top: 40%;
  right: -1px;
}
#modal-subscribe
  .modal-dialog
  .modal-content
  .subscribe-shape
  img:nth-child(3) {
  bottom: 0;
  left: 50px;
}
#modal-subscribe .modal-dialog .modal-content .btn-close {
  position: absolute;
  top: -13px;
  right: 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 12px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 3px solid #ddd;
  background-color: #fff;
  color: #363636;
  padding: 0;
  opacity: 1;
}
#modal-subscribe .modal-dialog .modal-content .btn-close:hover {
  border: 3px solid #434e6e;
}
#modal-subscribe .modal-dialog .modal-content .btn-close:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
#modal-subscribe .modal-dialog .modal-content .modal-body {
  padding: 0;
}
#modal-subscribe .subscribe-img {
  margin-bottom: 30px;
  text-align: center;
}
#modal-subscribe .subscribe-content {
  margin-bottom: 30px;
}
#modal-subscribe .subscribe-content h2 {
  font-size: 25px;
  margin-bottom: 5px;
}
#modal-subscribe .subscribe-content p {
  margin-bottom: 15px;
}
#modal-subscribe .subscribe-content form .form-control {
  height: 50px;
  border: 0;
  border-radius: 5px;
  background-color: #f5f5f5;
  margin-bottom: 15px;
}
.mainDivRating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  /* padding-bottom: 15px; */
}
.productImg {
  height: auto;
  width: 120px;
}
.productImg:active {
  border: 1px solid red;
}
.sizChartDiv {
  display: flex;
  gap: 20px;
}
.mainDivSizeChart {
  padding: 20px 0px;
}

#modal-subscribe .subscribe-content form .form-control:focus {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#modal-subscribe .subscribe-content form .common-btn {
  width: 100%;
  display: block;
}
#modal-subscribe .subscribe-content form .validation-danger {
  color: #dc3545 !important;
  margin-top: 10px;
}
#modal-subscribe .subscribe-content form .validation-success {
  color: #28a745 !important;
  margin-top: 10px;
}
#modal-subscribe .subscribe-content form .inner-check {
  margin-top: 15px;
}
#modal-subscribe .subscribe-content form .inner-check .form-check {
  padding-left: 26px;
  margin-bottom: 5px;
}
#modal-subscribe
  .subscribe-content
  form
  .inner-check
  .form-check
  .form-check-input {
  margin-left: -26px;
  width: 15px;
  height: 15px;
  border-radius: 0;
  margin-top: 5px;
  border: 1px solid #363636;
}
#modal-subscribe
  .subscribe-content
  form
  .inner-check
  .form-check
  .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #363636;
}
#modal-subscribe
  .subscribe-content
  form
  .inner-check
  .form-check
  .form-check-input:checked {
  background-color: #363636;
}
#modal-subscribe
  .subscribe-content
  form
  .inner-check
  .form-check
  .form-check-label {
  font-size: 15px;
  color: #545454;
}
#modal-subscribe
  .subscribe-content
  form
  .inner-check
  .form-check
  .form-check-label
  a {
  display: inline-block;
  color: #f05454;
  font-weight: 600;
}
#modal-subscribe
  .subscribe-content
  form
  .inner-check
  .form-check
  .form-check-label
  a:hover {
  color: #545454;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  background: #434e6e;
}
.loader .pre-load {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 85px;
  height: 85px;
  border-radius: 50%;
  -webkit-perspective: 800px;
  perspective: 800px;
}
.loader .pre-load .inner {
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.loader .pre-load .inner.one {
  left: 0%;
  top: 0%;
  -webkit-animation: rotate-one 1s linear infinite;
  animation: rotate-one 1s linear infinite;
  border-bottom: 10px solid #fff;
}
.loader .pre-load .inner.two {
  right: 0%;
  top: 0%;
  -webkit-animation: rotate-two 1s linear infinite;
  animation: rotate-two 1s linear infinite;
  border-right: 10px solid #fff;
}
.loader .pre-load .inner.three {
  right: 0%;
  bottom: 0%;
  -webkit-animation: rotate-three 1s linear infinite;
  animation: rotate-three 1s linear infinite;
  border-top: 10px solid #fff;
}
@-webkit-keyframes rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@-webkit-keyframes rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@-webkit-keyframes rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
@keyframes rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 0;
  right: 15px;
  color: #f05454;
  z-index: 4;
  width: 45px;
  text-align: center;
  height: 45px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.go-top i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  font-size: 40px;
  line-height: 45px;
}
.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.wishListTable {
  margin: 50px 0px;
}
.listTable {
  margin-bottom: 20px;
}
.go-top.active {
  bottom: 2%;
  -webkit-transform: translateY(-1%);
  transform: translateY(-1%);
  opacity: 1;
  visibility: visible;
}
.go-top:hover,
.go-top:focus {
  color: #f05454;
}
.go-top:hover i:first-child,
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:hover i:last-child,
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.go-top.two {
  color: #434e6e;
}
.clearTitle {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}
.sizeBackground {
  background-color: #2bc3a6;
}
.img-preview-section-container {
  display: none;
  height: auto;
  width: auto;
  background-color: #fff;
}
.temAndCondition {
  padding: 20px;
}
.details-gallery:hover .img-preview-section-container {
  display: block;
  position: absolute;
  left: 350px;
  bottom: 55%;
  /* background: center; */
}
/* .img-preview-section-container .img-preview-section {
  transform: scale(2.5) !important;
} */