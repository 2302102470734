/* scrolll btn css */

.maintenance-container {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* Adjust the width as needed */
  max-width: 400px; /* Adjust the maximum width as needed */
  padding: 20px;
  background-color: #f5f5f5; /* Adjust the background color */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.scroll-btn {
  background-color: #e78585;
  border: none;
  border-radius: 25px;
  bottom: 20px;
  color: #fff;
  cursor: pointer;
  padding: 7px 9px;
  position: fixed;
  right: 20px;
  transition: background-color 0.3s;
  z-index: 1;
}
.scroll-btn svg {
  font-size: 22px;
}
.scroll-btn:hover {
  background-color: grey; /* Optional: Change button color on hover */
}

.join-button {
  position: relative;
  color: #ffffff;
  margin-left: -82px;
}
.user-menu {
  color: #000000;
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  top: 100%;

  width: max-content;
  max-width: max-content;
  padding: 20px 20px 10px;
  border-bottom: 2px solid #ababab;
  border-radius: 0 0 10px 10px;
}
.user-menu a {
  font-size: 13px;
  line-height: normal;
  text-transform: capitalize;
  margin-right: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  text-align: left;
  padding: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
.user-menu a:hover {
  color: red;
}
/* SKate your boot css */
.card-img {
  transition: all 4000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.button-style {
  background-color: #e5e5e5;
  color: #000;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-style:hover {
  background-color: #000;
  color: #fff;
}

.card-img:hover {
  transform: scale(1.15) !important;
}

.pull-left {
  float: left;
}
.buildContainer {
  padding: 60px 0px;
}
.dashboard_content button {
  font-weight: 500;
  border: 0;
  background: #222222;
  color: #ffffff;
  padding: 0 15px;
  height: 44px;
  width: 165px;
  line-height: 30px;
  border-radius: 4px;
  margin: 0 0px;
  margin-top: 12px;
}
.sticky-div{
  position: sticky;
  top: 0px;
  z-index: 9;
  background-color: white;
  padding-top: 2px;
  padding-bottom: 2px;
}
.pull-right {
  float: right !important;
}
.shop_wrapper .single_product {
  position: relative;
  border-top: 0;
}
.single_product {
  border-radius: 4px;
  background: #ffffff;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
}
a {
  cursor: pointer !important;
}
figure {
  padding: 0;
  margin: 0;
}
.product_thumb {
  position: relative;
  padding: 10px;
}
.single_product .product_thumb {
  overflow: hidden;
}
.single_product .product_thumb a.primary_img {
  overflow: hidden;
}

.single_product .product_thumb a.primary_img img {
  -webkit-transition: all 4000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -moz-transition: all 4000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 4000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.product_content {
  position: relative;
  text-align: center;
}
.product_content_inner {
  padding: 20px 20px 2px 20px;
}
.product_content h4 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 13px;
}
.price_box span.current_price {
  font-size: 16px;
  font-weight: 500;
  color: #49b857;
}
.price_box span {
  line-height: 16px;
  font-size: 14px;
}
.product_variant.size {
  margin-bottom: 30px;
}
.clear {
  clear: both;
}
.checkout_form input {
  border: 1px solid #ebebeb;
  background: none;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  color: #222222;
}
.single_product:hover {
  -webkit-box-shadow: 0px 0px 13.5px 1.5px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 0px 13.5px 1.5px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 13.5px 1.5px rgba(0, 0, 0, 0.07);
}
.single_product:hover .product_thumb a.primary_img img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.single_product .product_thumb a.primary_img img {
  -webkit-transition: all 4000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -moz-transition: all 4000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 4000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}
.select-img {
  border: 1px solid #efefef;
  margin-bottom: 20px;
}
.select-img img {
  padding: 5px;
  width: 100%;
}
.select-img h5 {
  padding: 10px;
  margin-bottom: 0;
  background-color: #222;
  color: #fff;
  font-size: small;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
table {
  border-collapse: collapse;
}
.skate-td {
  border-top: 1px solid #dee2e6 !important;
  border: 1px solid transparent !important;
}
.quickbtn {
  border: 2px solid #1f1f1f;
  height: 45px;
  line-height: 41px;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 4px;
  max-width: 220px;
  width: 100%;
}
.card-m-bottom {
  margin-top: 12px;
}
.pm-icon {
  right: 0;
}
.bredcrumb {
  background: #f8f8f8;
  padding-top: 14px;
  margin-top: 12px;
}
.newsletter_inner {
  padding: 25px 35px;
  border: 1px solid #ebebeb;
  background: #ffffff;
}

.subscribe_content p {
  font-size: 14px;
  line-height: 25px;
}
.subscribe_form form {
  width: 100%;
  position: relative;
  background: #ffffff;
  border-radius: 4px;
}
.subscribe_form form input {
  width: 100%;
  border: 1px solid #ebebeb;
  background: none;
  padding: 0 158px 0 15px;
  height: 50px;
  font-size: 14px;
  color: #666;
  border-radius: 4px;
}
.subscribe_form form button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 20px;
  width: 140px;
  background: #272727;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  height: 100%;
  border: 0;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

@media (min-width: 1200px) {
  .col-xl-20 {
    -ms-flex: 0 0 20%;
    -webkit-box-flex: 0;
    flex: 0 0 20%;
    max-width: 20% !important;
  }
}

/* Address Book Page Css */
.account_page_bg {
  background: #ffffff;
  padding: 40px 0 40px;
}
.tab-content > .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
}
.dashboard_content h3 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 15px;
}
.mt-30 {
  margin-top: 30px;
}
.default-add {
  padding: 35px 35px;
  margin-top: 0 !important;
}
.cart-totals {
  padding: 15px 15px 35px 15px;
  border-radius: 8px;
  background-color: #f5f5f5;
  margin-bottom: 30px;
  margin-top: 32%;
}
.head-add h5 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px dotted #ccc;
  padding-bottom: 10px;
}
.dashboard_content button {
  font-weight: 500;
  border: 0;
  background: #222222;
  color: #ffffff;
  padding: 0 15px;
  height: 44px;
  width: 165px;
  line-height: 30px;
  border-radius: 4px;
  margin: 0 0px;
  margin-top: 12px;
}
.address-col {
  background: grey;
  color: white;
  border: 1px solid;
}
.add-address .box-1 {
  border-style: dashed;
  height: 243px;
  width: 753px;
  border-width: 2px;
  box-sizing: border-box;
  border-color: #c7c7c7;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  border-radius: 4px;
  /* border: 1px #ddd solid; */
  background-color: #fff;
}
.add-address .box-1 .a-box {
  border-radius: 4px;
  position: relative;
  padding: 20px 26px !important;
}
.add-address .box-1 .a-box svg {
  font-size: 40px;
  color: #1b1b1b !important;
}
.add-address .box-1 .a-box h2 {
  color: #1b1b1b !important;
  font-weight: 600;
  font-size: 21px;
  line-height: 1.3;
  margin-top: 10px;
}
.dashboard_content button:hover {
  background: #c40316;
}

/* Order History Page CSS */
.heading p {
  font-size: 20px;
  font-weight: 600;
}

.no-order {
  padding: 40px;
  margin-bottom: 30px;
  display: flow-root;
  text-align: center;
  border: 2px dotted #000000;
  background: white;
}
.order-history-btn {
  background: black;
  border: none;
  padding: 10px 15px;
  font-weight: 600;
}
.order-history-btn:hover {
  background: #c40316;
}
.youtube-video {
  pointer-events: none;
}
